import React, {
  useState,
  useEffect,
  createContext,
  useLayoutEffect,
} from "react";

export const ScreenSizeContext = createContext();

export const ScreenSizeProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );

  const updateOrientation = () => {
    const matchesPortrait = window.matchMedia(
      "(orientation: portrait)"
    ).matches;
    setIsPortrait(matchesPortrait);
  };

  useEffect(() => {
    updateOrientation();

    const handleResize = () => {
      updateOrientation();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const handleInitialOrientation = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
      setIsReady(true);
    };

    handleInitialOrientation();

    window.addEventListener("DOMContentLoaded", handleInitialOrientation);

    return () => {
      window.removeEventListener("DOMContentLoaded", handleInitialOrientation);
    };
  }, [isPortrait]);

  // Definiera skärmstorleksgränser
  const isMobileScreen = window.innerWidth < 768;
  const isTabletScreen = window.innerWidth >= 768 && window.innerWidth < 1024;
  // Justera gränserna för skrivbordsstorlekar för att undvika överlappning
  const isSmallDesktopScreen =
    window.innerWidth >= 1024 && window.innerWidth < 1280;
  const isMediumDesktopScreen =
    window.innerWidth >= 1280 && window.innerWidth < 1440;
  const isLargeDesktopScreen =
    window.innerWidth >= 1440 && window.innerWidth < 1920;
  const isExtraLargeDesktopScreen = window.innerWidth >= 1920;

  return (
    <ScreenSizeContext.Provider
      value={{
        isReady,
        isPortrait,
        isMobileScreen,
        isTabletScreen,
        isSmallDesktopScreen,
        isMediumDesktopScreen,
        isLargeDesktopScreen,
        isExtraLargeDesktopScreen,
      }}
    >
      {children}
    </ScreenSizeContext.Provider>
  );
};

export default ScreenSizeProvider;
