export const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateForm2 = (firstname, message) => {
  const formErrors = {};
  let isValid = true;

  if (firstname.trim() === "") {
    formErrors.firstname = "FirstNameRequired";
    isValid = false;
  }

  if (message.trim() === "") {
    formErrors.message = "Message is required";
    isValid = false;
  }

  return { isValid, formErrors };
};
