export const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateForm = (
  firstname,
  surname,
  street,
  postcodeCity,
  email,
  message
) => {
  const formErrors = {};
  let isValid = true;

  if (firstname.trim() === "") {
    formErrors.firstname = "FirstNameRequired";
    isValid = false;
  }

  if (surname.trim() === "") {
    formErrors.surname = "SurnameRequired";
    isValid = false;
  }

  if (street.trim() === "") {
    formErrors.street = "StreetRequired";
    isValid = false;
  }

  if (postcodeCity.trim() === "") {
    formErrors.postcodeCity = "PostcodeCityRequired";
    isValid = false;
  }

  if (email.trim() === "") {
    formErrors.email = "Email is required";
    isValid = false;
  } else if (!isEmailValid(email)) {
    formErrors.email = "Invalid email format";
    isValid = false;
  }

  // if (message.trim() === "") {
  //   formErrors.message = "Message is required";
  //   isValid = false;
  // }

  return { isValid, formErrors };
};
