import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Gallery from "./Gallery";
import Order from "./Order";
import About from "./About";
import Contact from "./Contact";
import Integrity from "./Integrity";
import Abisko from "./Abisko";
import Ängsö from "./Ängsö";
import AxeltorpsSkogar from "./AxeltorpsSkogar";
import Bohuskusten from "./Bohuskusten";
import Fårö from "./Fårö";
import Flottsbro from "./Flottsbro";
import Gålö from "./Gålö";
import Gömmaren from "./Gömmaren";
import HovsHallar from "./HovsHallar";
import Kullaberg from "./Kullaberg";
import Landsort from "./Landsort";
import Öland from "./Öland";
import Oxelösund from "./Oxelösund";
import Söderåsen from "./Söderåsen";
import ToröStenstrand from "./ToröStenstrand";
import Tyresta from "./Tyresta";
import Utö from "./Utö";
import Vindeln from "./Vindeln";
import Vällinge from "./Vällinge";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/order" element={<Order />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/integrity" element={<Integrity />} />
        <Route path="/abisko" element={<Abisko />} />
        <Route path="/ängsö" element={<Ängsö />} />
        <Route path="/axeltorp" element={<AxeltorpsSkogar />} />
        <Route path="/bohuskusten" element={<Bohuskusten />} />
        <Route path="/fårö" element={<Fårö />} />
        <Route path="/flottsbro" element={<Flottsbro />} />
        <Route path="/gålö" element={<Gålö />} />
        <Route path="/gömmaren" element={<Gömmaren />} />
        <Route path="/hovshallar" element={<HovsHallar />} />
        <Route path="/kullaberg" element={<Kullaberg />} />
        <Route path="/landsort" element={<Landsort />} />
        <Route path="/öland" element={<Öland />} />
        <Route path="/oxelösund" element={<Oxelösund />} />
        <Route path="/söderåsen" element={<Söderåsen />} />
        <Route path="/toröstenstrand" element={<ToröStenstrand />} />
        <Route path="/tyresta" element={<Tyresta />} />
        <Route path="/utö" element={<Utö />} />
        <Route path="/vindeln" element={<Vindeln />} />
        <Route path="/vällinge" element={<Vällinge />} />
      </Routes>
    </Router>
  );
}

export default App;
