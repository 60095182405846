import React, { useEffect, useState, useRef, useContext } from "react";
import Modal from "react-modal";
import logoLiggande from "./images/Diverse/logo/LOGO_liggande2.png";
import GardientBackground from "./images/Diverse/logo/gradient_pattern2.png";
import instagramIcon from "./images/Diverse/Pilar-instagram-vattenstämpel/Instagram-Icon_small.png";
import swishLogo from "./images/Diverse/logo/SwishLogga.png";
import { useLanguage, getTranslation } from "./LanguageProvider";
import { ScreenSizeProvider, ScreenSizeContext } from "./ScreenSizeProvider";
import english from "./images/Diverse/Pilar-instagram-vattenstämpel/english.jpg";
import swedish from "./images/Diverse/Pilar-instagram-vattenstämpel/swedish.jpg";
import shoppingcart from "./images/Diverse/Pilar-instagram-vattenstämpel/shoppingcart2.png";
import headerImage from "./images/Diverse/headers/Gömmaren_header.jpg";
import "./css/fontfamily-OpenSansItalic.css";
import "./css/fontfamily-EBGaramound.css";
import "./css/app2.css";
import { validateForm } from "./validateForm.js";
import Cookies from "js-cookie";
import QRCode from "qrcode";

const printSizes = [
  { value: "20x25", label: "20x25" },
  { value: "30x40", label: "30x40" },
  { value: "40x50", label: "40x50" },
  { value: "50x70", label: "50x70" },
  { value: "60x80", label: "60x80" },
  { value: "70x100", label: "70x100" },
];
const sizeLookup = {
  "20x25": "14x19 cm",
  "30x40": "19x29 cm",
  "40x50": "29x39 cm",
  "50x70": "39x59 cm",
  "60x80": "49x69 cm",
  "70x100": "59x89 cm",
};
const deliveryCosts = [
  { size: "20x25", cost: 50 },
  { size: "30x40", cost: 70 },
  { size: "40x50", cost: 100 },
  { size: "50x70", cost: 120 },
  { size: "60x80", cost: 140 },
  { size: "70x100", cost: 150 },
];
const passepartoutOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
const printMaterials = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
const headerStyle = {
  border: "1px solid #000",
  padding: "4px",
  fontSize: "11pt",
  color: "#878787",
};
const itemStyle = {
  border: "1px solid #000",
  padding: "4px",
  fontSize: "11pt",
  color: "#fff",
};

// Navigation component
const Navigation = () => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  // State to keep track of the selected language
  const { selectedLanguage, handleLanguageChange, translate } = useLanguage();

  const logoStyle = {
    maxWidth: isMobileScreen
      ? isPortrait
        ? "20vh"
        : "40vh"
      : isTabletScreen
      ? isPortrait
        ? "15vh"
        : "25vh"
      : isSmallDesktopScreen
      ? isPortrait
        ? "15vh"
        : "30vh"
      : isMediumDesktopScreen
      ? isPortrait
        ? "30vh"
        : "30vh"
      : isLargeDesktopScreen
      ? isPortrait
        ? "35vh"
        : "35vh"
      : isExtraLargeDesktopScreen
      ? isPortrait
        ? "30vh"
        : "30vh"
      : "30vh",
  };
  const languageButtonsStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px" /* Avstånd mellan knapparna */,
    height: "100%",
    float: "right",
    maxWidth: isReady
      ? isMobileScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isTabletScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : "1.4em"
      : "1.4em",
    marginRight: isReady
      ? isMobileScreen
        ? isPortrait
          ? "0em"
          : "-18em"
        : isTabletScreen
        ? isPortrait
          ? "-20em"
          : "-16em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-32em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-32em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-34em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-34em"
        : "-34em"
      : "-34em",
  };
  const mobileScreenNavigationStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100000,
    boxShadow: "3px 0px 5px #000",
    maxHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const navLogoContainerStyleMobile = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: "5%",
    float: "left",
  };
  const navLogoContainerStyleLarge = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    float: "left",
    marginLeft: "5%",
  };
  const largeNavigationRowStyle = {
    width: "auto",
    float: "right",
    marginRight: "12%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };
  const navigationButtonStyle = {
    color: "#d4be6a",
    fontSize: "1rem",
    lineHeight: "45px",
    margin: 0,
    height: "45px",
    padding: 0,
  };

  const toggleSlider = () => {
    setIsSliderOpen(!isSliderOpen);
  };

  const closeSlider = () => {
    setIsSliderOpen(false);
  };

  /* Handle click outside the Navigation-slider */
  useEffect(() => {
    const closeSlider = () => {
      setIsSliderOpen(false);
    };

    const handleClickOutside = (event) => {
      const offCanvas = document.getElementById("offCanvasRight");
      if (offCanvas && !offCanvas.contains(event.target)) {
        closeSlider();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Large Screen Navigation */}
      <div className="slide-nav show-for-large" style={{ top: 0 }}>
        {/* Wrap logo inside slide-nav */}
        <div style={navLogoContainerStyleLarge}>
          <a href="/">
            <img
              className="nav-logo"
              src={logoLiggande}
              alt="Logo"
              style={logoStyle}
            />
          </a>
        </div>

        <div className="row" style={largeNavigationRowStyle}>
          <div className="top-bar">
            <ul className="dropdown menu" data-dropdown-menu>
              <li>
                <a href="/">{translate("Home")}</a>
              </li>
              <li>
                <a href="/gallery">{translate("Gallery")}</a>
              </li>
              <li>
                <a href="/order">{translate("Order info")}</a>
              </li>
              <li>
                <a href="/about">{translate("About")}</a>
              </li>
              <li>
                <a href="/contact">{translate("Contact")}</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Språkval-knappar */}
        <div className="language-buttons" style={languageButtonsStyle}>
          <img
            src={swedish} // Lägg till sökväg till den svenska flaggan
            alt="Swedish"
            className="language-button"
            onClick={() => handleLanguageChange("sv")}
          />
          <img
            src={english} // Lägg till sökväg till den engelska flaggan
            alt="English"
            className="language-button"
            onClick={() => handleLanguageChange("en")}
          />
        </div>
      </div>

      {/* Mobile Screen Navigation */}
      <div
        className="slide-mobile-navigation hide-for-large"
        style={mobileScreenNavigationStyle}
      >
        {/* Wrap logo inside slide-mobile */}
        <div style={navLogoContainerStyleMobile}>
          <a href="/">
            <img
              className="nav-logo"
              src={logoLiggande}
              alt="Logo"
              style={logoStyle}
            />
          </a>
        </div>
        {/* Språkval-knappar */}
        <div className="language-buttons" style={languageButtonsStyle}>
          <img
            src={swedish}
            alt="Swedish"
            className="language-button"
            onClick={() => handleLanguageChange("sv")}
          />
          <img
            src={english}
            alt="English"
            className="language-button"
            onClick={() => handleLanguageChange("en")}
          />
        </div>

        {/* hamburger manu */}
        <div
          className="contain-to-grid"
          style={{ marginTop: "2.5px", marginBottom: "2.5px" }}
        >
          <div
            className="small-3 columns"
            style={{ float: "right", marginRight: "40%", width: "auto" }}
          >
            <div
              className="off-canvas-content"
              data-off-canvas-content
              style={{ float: "right" }}
            >
              <div className="nav-bar">
                <div className="nav-bar-right" style={{ float: "right" }}>
                  <ul className="menu">
                    <li>
                      <button
                        className="offcanvas-trigger"
                        type="button"
                        data-open="offCanvasRight"
                        aria-expanded={isSliderOpen ? "true" : "false"}
                        aria-controls="offCanvasRight"
                        style={navigationButtonStyle}
                        onClick={isSliderOpen ? closeSlider : toggleSlider} // Attach toggleSlider function to onClick event
                      >
                        <div className="hamburger" style={{ color: "#d4be6a" }}>
                          <span className="line"></span>
                          <span className="line"></span>
                          <span className="line"></span>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Meny-slidern */}
        <div
          className={`off-canvas position-right ${
            isSliderOpen ? "is-open" : ""
          }`}
          id="offCanvasRight"
          data-off-canvas
          style={{ zIndex: 1000000 }}
        >
          <ul
            className="vertical menu"
            data-accordion-menu
            style={{ marginTop: "30px" }}
          >
            <li className="active">
              <a href="/">{translate("Home")}</a>
            </li>
            <li className=" ">
              <a href="/gallery">{translate("Gallery")}</a>
            </li>
            <li className=" ">
              <a href="/order">{translate("Order info")}</a>
            </li>
            <li className=" ">
              <a href="/about">{translate("About")}</a>
            </li>
            <li className=" ">
              <a href="/contact">{translate("Contact")}</a>
            </li>
            <li style={{ marginTop: "30px", textAlign: "left" }}>
              <p>
                <a href="https://www.instagram.com/tzettmarker/">
                  <img
                    className="nav-logo-small-menu"
                    src={instagramIcon}
                    alt="Instagram"
                  />
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

// Header
const Header = () => {
  // Hämta ScreenSizeContext from ScreenSizeProvider.js
  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  const headerImageStyle = {
    textAlign: "center",
    height: "auto",
    marginTop: isReady
      ? isMobileScreen
        ? isPortrait
          ? "4.4em"
          : "3.5em"
        : isTabletScreen
        ? isPortrait
          ? "3.5em"
          : "3.5em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "3.5em"
          : "5.5em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "3.5em"
          : "5.5em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "3.5em"
          : "5.5em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "5.5em"
          : "5.5em"
        : "5.5em"
      : "5.5em",
  };

  return (
    <div className="main-content-row">
      <div className="header-image" style={headerImageStyle}>
        <img
          src={headerImage}
          alt="HeaderImage"
          style={{ boxShadow: "rgb(0, 0, 0) 3px 1px 5px" }}
        />
      </div>
    </div>
  );
};

const OrderSummary = ({ selectedImages }) => {
  const numberOfSelectedImages = selectedImages.length;

  return (
    <div>
      <span className="basket-icon"></span>
      {numberOfSelectedImages > 0 && (
        <span className="red-count">{numberOfSelectedImages}</span>
      )}
    </div>
  );
};

const Gallery = () => {
  const [konverterade1Images, setKonverterade1Images] = useState([]);
  const [konverterade2Images, setKonverterade2Images] = useState([]);
  const [openModalIndex, setOpenModalIndex] = useState(null);
  const [columnCount, setColumnCount] = useState(3);
  const [modalImageSource, setModalImageSource] = useState(
    konverterade2Images[openModalIndex]?.src
  );
  const [showDropdowns, setShowDropdowns] = useState(false);
  const [orderButtonClicked, setOrderButtonClicked] = useState(false);
  const [selectedOptionsText, setSelectedOptionsText] = useState("");
  const [selectedPrintSize, setSelectedPrintSize] = useState("20x25");
  const [currentSize, setCurrentSize] = useState("14x19");
  const [selectedPassepartout, setSelectedPassepartout] = useState("Yes");
  const [selectedPrintMaterial, setSelectedPrintMaterial] = useState("Yes");
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [isShoppingSliderOpen, setIsShoppingSliderOpen] = useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDropdownOptions, setSelectedDropdownOptions] = useState([]);
  const sliderRef = useRef(null);
  const [modalImages, setModalImages] = useState([...konverterade1Images]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(500);
  const [quantity, setQuantity] = useState(1); // Antal tryck på plus/minus
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(currentPrice * quantity);
  const [isLocalPickup, setIsLocalPickup] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [cartCount, setCartCount] = useState(0);
  const modalRef = useRef(null);
  const [isServerError, setIsServerError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Hämta ScreenSizeContext from ScreenSizeProvider.js
  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  // Shoppingcart-style
  const shoppingCartStyle = {
    position: "fixed",
    zIndex: 10000,
    top: isReady
      ? isMobileScreen
        ? isPortrait
          ? "4.8em"
          : "5.8em"
        : isTabletScreen
        ? isPortrait
          ? "6em"
          : "7em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "6em"
          : "7em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "7em"
          : "7em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "8em"
          : "8em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "8em"
          : "8em"
        : "7em"
      : "7em",
    right: isReady
      ? isMobileScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isTabletScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "2em"
          : "2em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "2em"
          : "2em"
        : "1em"
      : "1em",
    display: isModalOpen ? "none" : "block",
  };

  // State to keep track of the selected language
  const { selectedLanguage, handleLanguageChange, translate } = useLanguage();

  const modalStyles = {
    shouldCloseOnOverlayClick: true,
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      inset: 0,
      zIndex: 9999,
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pointerEvents: "none",
      columnCount: columnCount,
    },
    content: {
      overflow: "auto", // Enable scrolling if image exceeds container size
      maxHeight: "88vh", // Adjust the value as needed
      width: "100%",
      marginTop: isReady
        ? isMobileScreen
          ? isPortrait
            ? "15%"
            : "8%"
          : isTabletScreen
          ? isPortrait
            ? "8%"
            : "6%"
          : isSmallDesktopScreen
          ? isPortrait
            ? "8%"
            : "0%"
          : isMediumDesktopScreen
          ? isPortrait
            ? "10%"
            : "0%"
          : isLargeDesktopScreen
          ? isPortrait
            ? "15%"
            : "0%"
          : isExtraLargeDesktopScreen
          ? isPortrait
            ? "15%"
            : "0%"
          : "2%"
        : "0",
      background: "rgba(0, 0, 0, 0)",
      border: "none",
      position: "relative",
      inset: "0px",
      overflow: "auto",
      pointerEvents: "auto", // Enable pointer events on the content
      outline: "none",
      display: "flex",
      flexDirection: "column", // Change to "column" for vertical alignment
      alignItems: "center", // Horizontal alignment
    },
    imageContainer: {
      flex: "1 1 auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "auto",
      maxHeight: "80vh", // Adjust the value as needed
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "auto",
      height: "auto",
      objectFit: "contain", // Maintain the aspect ratio of the image
    },
    landscapeImage: {
      maxHeight: "80vh", // Adjust the value as needed for landscape images
    },
    portraitImage: {
      maxWidth: "80vw", // Adjust the value as needed for portrait images
    },
  };
  const gallerywrapperStyle = {
    columnCount: isMobileScreen
      ? isPortrait
        ? 2
        : 3
      : isTabletScreen
      ? isPortrait
        ? 3
        : 4
      : isSmallDesktopScreen
      ? isPortrait
        ? 4
        : 5
      : isMediumDesktopScreen
      ? isPortrait
        ? 4
        : 5
      : isLargeDesktopScreen
      ? 6
      : isExtraLargeDesktopScreen
      ? 6
      : 3,
    columnGap: "2.5px",
  };
  const cartCountStyle = {
    color: "red",
    fontSize: "28px",
    fontWeight: "bold",
    position: "relative",
    marginLeft: "-8px",
    top: "-8px",
  };
  const sliderContainerStyle = {
    zIndex: 1000000,
    width: isMobileScreen
      ? isPortrait
        ? "320px"
        : "350px"
      : isTabletScreen
      ? "400px"
      : isSmallDesktopScreen
      ? "400px"
      : isMediumDesktopScreen
      ? "400px"
      : isLargeDesktopScreen
      ? "450px"
      : isExtraLargeDesktopScreen
      ? "450px"
      : "350px",
    msTransform: isShoppingSliderOpen ? "translateX(0)" : "translateX(100%)",
    transform: isShoppingSliderOpen ? "translateX(0)" : "translateX(100%)",
  };
  const sliderTableStyle = {
    borderCollapse: "collapse",
    background: "transparent",
    width: "100%",
    marginBottom: "20px",
  };
  const sliderPriceStyle = {
    color: "#878787",
    fontSize: "12pt",
    margin: 0,
    padding: 0,
  };
  const sliderDeliverycostStyle = {
    color: "#878787",
    fontSize: "12pt",
    margin: 0,
    padding: 0,
  };
  const sliderTotalPriceStyle = {
    textAlign: "left",
    fontSize: "16pt",
    margin: 0,
    padding: 0,
    marginBottom: 50,
    color: "#ffffff",
  };
  const sliderConfirmButtonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    fontSize: "12pt",
    color: "#ffffff",
    border: "none",
  };
  const sliderServerErrorStyle = {
    color: "red",
    margin: "25px 0",
    textAlign: "center",
    fontSize: "medium",
  };
  const sliderNoItemsStyle = {
    color: "#878787",
    textAlign: "center",
    margin: "0 auto",
    marginTop: "20px",
  };
  const sliderYourShoppingcartStyle = {
    color: "#fff",
    fontSize: "20pt",
    textAlign: "center",
    marginTop: "40px",
  };

  /* Fetch images */
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const konverterade1Context = require.context(
          "./images/Gömmaren/Konverterade1",
          false,
          /\.(jpg|jpeg)$/
        );
        const konverterade2Context = require.context(
          "./images/Gömmaren/Konverterade2",
          false,
          /\.(jpg|jpeg)$/
        );

        const konverterade1Filenames = konverterade1Context.keys();
        const konverterade2Filenames = konverterade2Context.keys();

        const konverterade1Promises = konverterade1Filenames.map((filename) =>
          import(`./images/Gömmaren/Konverterade1/${filename.slice(2)}`)
        );
        const konverterade2Promises = konverterade2Filenames.map((filename) =>
          import(`./images/Gömmaren/Konverterade2/${filename.slice(2)}`)
        );

        const konverterade1Modules = await Promise.all(konverterade1Promises);
        const konverterade2Modules = await Promise.all(konverterade2Promises);

        const konverterade1LoadedImages = konverterade1Modules.map(
          (module, index) => {
            const filename = konverterade1Filenames[index].substring(
              konverterade1Filenames[index].lastIndexOf("/") + 1
            );
            const src = module.default;
            return {
              filename,
              src,
            };
          }
        );

        const konverterade2LoadedImages = konverterade2Modules.map(
          (module, index) => {
            const filename = konverterade2Filenames[index].substring(
              konverterade2Filenames[index].lastIndexOf("/") + 1
            );
            const src = module.default;
            return {
              filename,
              src,
            };
          }
        );

        setKonverterade1Images(konverterade1LoadedImages);
        setKonverterade2Images(konverterade2LoadedImages);
      } catch (error) {
        console.error("Error loading images:", error);
        setKonverterade1Images([]);
        setKonverterade2Images([]);
      }
    };

    fetchImages();
  }, []);

  const removeImageGroup = (index) => {
    const updatedSelectedImages = selectedImages.filter((_, i) => i !== index);
    const updatedDropdownOptions = selectedDropdownOptions.filter(
      (_, i) => i !== index
    );

    setSelectedImages(updatedSelectedImages);
    setSelectedDropdownOptions(updatedDropdownOptions);

    // Uppdatera cookies med de nya listorna
    Cookies.set("selectedImages", JSON.stringify(updatedSelectedImages));
    Cookies.set(
      "selectedDropdownOptions",
      JSON.stringify(updatedDropdownOptions)
    );
  };

  useEffect(() => {
    const savedSelectedImages = Cookies.get("selectedImages");
    const savedDropdownOptions = Cookies.get("selectedDropdownOptions");
    if (savedSelectedImages && savedDropdownOptions) {
      setSelectedImages(JSON.parse(savedSelectedImages));
      setSelectedDropdownOptions(JSON.parse(savedDropdownOptions));
    }
  }, []); // Använd tom beroende-array för att köra detta bara vid första renderingen

  /* Price change */
  useEffect(() => {
    setTotalPrice(currentPrice * quantity);
  }, [currentPrice, quantity]);
  /* Higher Quantity */
  const incrementValue = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages[index].quantity += 1;
    setSelectedImages(updatedImages);
    setTotalPrice(currentPrice * updatedImages[index].quantity);
  };
  /* Lower Quantity */
  const decrementValue = (index) => {
    const updatedImages = [...selectedImages];
    if (updatedImages[index].quantity > 1) {
      updatedImages[index].quantity -= 1;
      setSelectedImages(updatedImages);
      setTotalPrice(currentPrice * updatedImages[index].quantity);
    } else {
      removeImageGroup(index);
    }
  };

  /* After Confirm, reset quantity and price */
  const resetQuantityAndPrice = () => {
    setQuantity(1);
    setTotalPrice(currentPrice);
  };

  /* Handle size change in dropwdowns */
  const handlePrintSizeChange = (event) => {
    const printSizeValue = event.target.value;
    setSelectedPrintSize(printSizeValue);

    setCurrentSize(sizeLookup[printSizeValue] || "Storlek ej tillgänglig");

    // Update the selected options text based on the selected print size value
    const printSizeOption = printSizes.find(
      (option) => option.value === printSizeValue
    );
    if (printSizeOption) {
      setSelectedOptionsText(
        (prevOptionsText) =>
          `Passepartout: ${selectedPassepartout}, Size: ${printSizeOption.label}, Frame: ${selectedPrintMaterial}`
      );
    }

    // Update price based on print size
    let price = 0;

    if (printSizeOption) {
      price = printSizeOption.price;
    }

    setCurrentPrice(price);
    setTotalPrice(price * quantity);
  };

  // Update the modalImages state when the selectedPassepartout changes
  useEffect(() => {
    const defaultModalImage =
      selectedPassepartout === "Yes"
        ? konverterade2Images
        : konverterade1Images;
    setModalImages(defaultModalImage);
  }, [selectedPassepartout, konverterade1Images, konverterade2Images]);

  useEffect(() => {
    let price = currentPrice;

    // Update price based on passepartout option
    if (selectedPassepartout === "No") {
      price -= 200;
    } else {
      price += 200;
    }

    setCurrentPrice(price);
  }, [selectedPassepartout]);

  const calculateDeliveryCost = (selectedOptions) => {
    let maxDeliveryCost = 0;

    if (isLocalPickup) return 0;

    // Loopa genom alla valda alternativ och hitta det högsta fraktpriset
    selectedOptions.forEach((option) => {
      const cost = getDeliveryCostForSize(option.printSize);
      if (cost > maxDeliveryCost) {
        maxDeliveryCost = cost;
      }
    });

    // Returnera det högsta fraktpriset som den totala fraktkostnaden
    return maxDeliveryCost;
  };

  // Funktion för att bestämma fraktkostnad baserat på storlek
  const getDeliveryCostForSize = (size) => {
    switch (size) {
      case "20x25":
        return 50;
      case "30x40":
        return 75;
      case "40x50":
        return 100;
      case "50x70":
        return 125;
      case "60x80":
      case "70x100":
        return 150;
      default:
        return 50; // Standardkostnad om storleken inte matchar någon av de ovan
    }
  };

  /* Handle passepartout change in dropwdowns */
  const handlePassepartoutChange = (event) => {
    const passepartoutValue = event.target.value;
    setSelectedPassepartout(passepartoutValue);

    // Update the selected options text based on the selected passepartout value
    const passepartoutOption = passepartoutOptions.find(
      (option) => option.value === passepartoutValue
    );
    if (passepartoutOption) {
      setSelectedOptionsText(
        (prevOptionsText) =>
          `Passepartout: ${passepartoutOption.label}, Size: ${selectedPrintSize}, Frame: ${selectedPrintMaterial}`
      );
    }

    // Update the modal images if passepartout is changed
    if (passepartoutOption.label === "Yes") {
      setModalImages([...konverterade2Images]);
    } else {
      setModalImages([...konverterade1Images]);
    }
  };

  /* Handle frame change in dropdowns */
  const handlePrintMaterialChange = (event, index) => {
    const printMaterialValue = event.target.value;
    setSelectedPrintMaterial(printMaterialValue);

    // Update the selected options text based on the selected print material value
    const printMaterialOption = printMaterials.find(
      (option) => option.value === printMaterialValue
    );
    if (printMaterialOption) {
      setSelectedOptionsText(
        (prevOptionsText) =>
          `Passepartout: ${selectedPassepartout}, Size: ${selectedPrintSize}, Frame: ${printMaterialOption.label}`
      );
    }
  };

  useEffect(() => {
    let price;

    // Set prices based on selected print size, passepartout, and frame
    if (selectedPrintSize === "20x25") {
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 300;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 400;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 450;
      } else {
        price = 500;
      }
    } else if (selectedPrintSize === "30x40") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 400;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 500;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 700;
      } else {
        price = 750;
      }
    } else if (selectedPrintSize === "40x50") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 500;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 650;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 800;
      } else {
        price = 900;
      }
    } else if (selectedPrintSize === "50x70") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 600;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 800;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 1100;
      } else {
        price = 1200;
      }
    } else if (selectedPrintSize === "60x80") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 700;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 950;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 1300;
      } else {
        price = 1400;
      }
    } else if (selectedPrintSize === "70x100") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 800;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 1100;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 1700;
      } else {
        price = 1900;
      }
    }

    setCurrentPrice(price);
  }, [selectedPrintSize, selectedPassepartout, selectedPrintMaterial]);

  useEffect(() => {
    setTotalPrice(currentPrice * quantity);
  }, [currentPrice, quantity]);

  const openModal = (index) => {
    document.body.style.overflow = "hidden";
    setIsModalOpen(true);
    setOpenModalIndex(index);
  };

  const closeModal = () => {
    document.body.style.overflow = "";
    setIsModalOpen(false);
    setOpenModalIndex(null);
    setSelectedPassepartout("Yes");
    setCurrentSize("14x19 cm");
    setIsConfirmClicked(false);
    handleSliderClose();
  };

  const closeModal2 = () => {
    setIsShoppingSliderOpen();
  };

  /* Handle click outside the modal */
  useEffect(() => {
    const handleModalClickOutside = (event) => {
      const modalContainer = document.querySelector(
        `#galleryModal${openModalIndex + 1}`
      );
      const screenWidth = window.innerWidth;
      const rightBoundary = screenWidth * 0.95;

      if (
        modalContainer &&
        !modalContainer.contains(event.target) &&
        event.clientX < rightBoundary
      ) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleModalClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleModalClickOutside);
    };
  }, [openModalIndex]);

  const handleImageClick = (index) => {
    setIsShoppingSliderOpen(false);
  };

  const handleClick = (index) => {
    handleImageClick(index);
    openModal(index);
  };

  /* Buy this photo click */
  const handleBuyPrintClick = () => {
    setShowDropdowns((prevShowDropdowns) => !prevShowDropdowns);
  };

  // Hämta kundvagnsdata från cookies när komponenten laddas
  useEffect(() => {
    const savedSelectedImages = Cookies.get("selectedImages");
    if (savedSelectedImages) {
      setSelectedImages(JSON.parse(savedSelectedImages));
    }
  }, []);

  const updateAndSaveToCookies = (image, dropdownOptions) => {
    setSelectedImages((prevSelectedImages) => [...prevSelectedImages, image]);
    setSelectedDropdownOptions((prevDropdownOptions) => [
      ...prevDropdownOptions,
      dropdownOptions,
    ]);

    Cookies.set("selectedImages", JSON.stringify([...selectedImages, image]));
    Cookies.set(
      "selectedDropdownOptions",
      JSON.stringify([...selectedDropdownOptions, dropdownOptions])
    );
  };

  const addtobasketClick = (index) => {
    const imageUrl =
      selectedPassepartout === "Yes"
        ? konverterade2Images[index].src
        : konverterade1Images[index].src;

    const selectedImage = {
      imageUrl,
      filename: `Image ${index + 1}`,
      price: currentPrice,
      frame: selectedPrintMaterial === "Yes" ? "Yes" : "No",
      quantity: 1,
    };

    const dropdownOptions = {
      passepartout: selectedPassepartout,
      printSize: selectedPrintSize,
      printMaterial: selectedPrintMaterial,
    };

    updateAndSaveToCookies(selectedImage, dropdownOptions);

    setOrderButtonClicked(true);
    setShowDropdowns(false);
    setCurrentPrice(500);
    setSelectedPrintSize("20x25");
    setSelectedPassepartout("Yes");
    setSelectedPrintMaterial("Yes");
    setIsShoppingSliderOpen(!isShoppingSliderOpen);
  };

  useEffect(() => {
    const savedSelectedImages = Cookies.get("selectedImages");
    const savedDropdownOptions = Cookies.get("selectedDropdownOptions");

    if (savedSelectedImages && savedDropdownOptions) {
      setSelectedImages(JSON.parse(savedSelectedImages));
      setSelectedDropdownOptions(JSON.parse(savedDropdownOptions));
    }
  }, []);

  const updateQuantity = (index, value) => {
    if (value < 1) {
      removeImageGroup(index);
      return;
    }

    setSelectedImages((prevSelectedImages) => {
      const updatedImages = [...prevSelectedImages];
      updatedImages[index].quantity = value;
      return updatedImages;
    });
  };

  useEffect(() => {
    const newTotalQuantity = selectedImages.reduce(
      (total, image) => total + image.quantity,
      0
    );

    setTotalQuantity(newTotalQuantity);
    Cookies.set("selectedImages", JSON.stringify(selectedImages));
    Cookies.set(
      "selectedDropdownOptions",
      JSON.stringify(selectedDropdownOptions)
    );

    const newTotalPrice = currentPrice * newTotalQuantity;
    setTotalPrice(newTotalPrice);
  }, [selectedImages, selectedDropdownOptions]);

  const currentTotalPrice = selectedImages.reduce(
    (total, image) => total + image.price * image.quantity,
    0
  );

  /* Handle click outside the shopping-slider */
  useEffect(() => {
    const handleShoppingSliderClickOutside = (event) => {
      const slider = sliderRef.current;
      const screenWidth = window.innerWidth;
      const rightBoundary = screenWidth * 0.95;

      if (
        slider &&
        !slider.contains(event.target) &&
        !event.target.classList.contains("modal-image") &&
        event.clientX < rightBoundary
      ) {
        setIsShoppingSliderOpen(false);
      }
    };

    document.addEventListener("mousedown", handleShoppingSliderClickOutside);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleShoppingSliderClickOutside
      );
    };
  }, [openModalIndex]);

  const handleSliderClose = () => {
    if (!isConfirmClicked) {
      setIsSliderOpen(false);
    }
  };

  const handleCartIconClick = () => {
    setIsShoppingSliderOpen(true);
    setIsConfirmClicked(false); // Reset the confirmation status
  };

  const formSubmit = (event) => {
    event.preventDefault();

    const { isValid, formErrors } = validateForm(
      event.target.firstname.value,
      event.target.surname.value,
      event.target.street.value,
      event.target.postcodeCity.value,
      event.target.email.value,
      event.target.Message.value
    );

    if (!isValid) {
      setFormErrors(formErrors);
      return;
    }

    const form = event.target;
    const formData = new FormData(form);

    formData.append("selectedImages", JSON.stringify(selectedImages));
    formData.append(
      "selectedDropdownOptions",
      JSON.stringify(selectedDropdownOptions)
    );
    formData.append("quantity", quantity);
    formData.append("totalPrice", totalPrice);

    QRCode.toDataURL("Här är data som ska kodas", function (err, url) {
      if (err) {
        console.error(err);
        return;
      }

      formData.append("qrcode", url);

      // Placera fetch inuti callback-funktionen för QRCode.toDataURL
      fetch("https://tzettmarker.se/public/send_email.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            console.log(
              "Data Success. setIsSubmitted(true); och setIsServerError(false);"
            );
            // Om servern svarade med success: true
            setIsConfirmClicked(true);
            setSelectedImages([]);
            setSelectedDropdownOptions([]);
            resetQuantityAndPrice();
            setIsSubmitted(true);
            setIsServerError(false);
          } else {
            // Om servern svarade med success: false
            console.log(
              "Data Not Success. setIsSubmitted(true); och setIsServerError(true);"
            );
            setIsConfirmClicked(false);
            setIsSubmitted(true);
            setIsServerError(true);
          }
        })
        .catch((error) => {
          // Om något går fel med förfrågan (t.ex. nätverksfel)
          console.log("Fetch Error: ", error);
          setIsConfirmClicked(false);
          setIsSubmitted(false);
          setIsServerError(true);
        });
    });
  };

  return (
    <div className="gallery-wrapper row expanded">
      {/* Bakgrundsbilden */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${GardientBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 1,
          zIndex: -1,
        }}
      />

      <div style={{ width: "100%", textAlign: "center" }}>
        <h1 style={{ marginBottom: "1%", marginTop: "5%" }}>Gömmaren</h1>
        <h3 style={{ marginBottom: "10%" }}>
          - {translate("GömmarenTitle")} -
        </h3>
      </div>

      <div style={gallerywrapperStyle}>
        {konverterade1Images.map((image, index) => (
          <div key={index} style={{ margin: "2.5px 0" }}>
            <a
              data-open={`galleryModal${index + 1}`}
              onClick={() => handleClick(index)}
            >
              <div>
                <img src={image.src} alt={image.filename} />
              </div>
            </a>
            {/* Modal component */}
            {openModalIndex === index && (
              <Modal
                isOpen={true}
                onRequestClose={closeModal}
                contentLabel="Gallery Modal"
                ariaHideApp={false}
                style={modalStyles}
                shouldCloseOnOverlayClick={true}
              >
                <div
                  className="custom-modal-content"
                  id={`galleryModal${index + 1}`}
                  ref={modalRef}
                  data-reveal
                >
                  {/* Modal content */}
                  <div className="row">
                    {/* Image */}
                    <div
                      className={`js-modal-preview modal-preview`}
                      style={{ paddingLeft: "0%", paddingRight: "0%" }}
                    >
                      {/* Modal img */}
                      <img
                        className="js-modal-preview modal-preview"
                        src={modalImages[index]?.src}
                        alt=""
                        onClick={() => handleImageClick(index)}
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "block",
                          ...(isPortrait
                            ? modalStyles.portraitImage
                            : modalStyles.landscapeImage),
                          border:
                            selectedPrintMaterial === "No"
                              ? "none"
                              : "6px solid black",
                        }}
                      />
                    </div>

                    {/* Buy Button */}
                    <div
                      className="large-12 medium-12 small-12 columns"
                      style={{ paddingLeft: "0%", paddingRight: "0%" }}
                    >
                      <p></p>
                      <button
                        className="gallery-button button success"
                        onClick={handleBuyPrintClick}
                      >
                        {translate("Buy This Photo")}
                      </button>
                    </div>

                    {/* Dropdowns */}
                    {showDropdowns && (
                      <div className="row">
                        <div className="large-4 medium-4 small-12 columns">
                          {/* Print Size */}
                          <label
                            htmlFor="print-size"
                            style={{ color: "#ffffff", fontSize: "12pt" }}
                          >
                            {translate("Print Size:")}
                          </label>
                          <select
                            id="print-size"
                            onChange={handlePrintSizeChange}
                          >
                            {printSizes.map((size) => (
                              <option key={size.value} value={size.value}>
                                {size.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="large-4 medium-4 small-12 columns">
                          {/* Passepartout */}
                          <label
                            htmlFor="passepartout"
                            style={{ color: "#ffffff", fontSize: "12pt" }}
                          >
                            Passepartout:
                          </label>
                          <select
                            id="passepartout"
                            onChange={handlePassepartoutChange}
                          >
                            {passepartoutOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="large-4 medium-4 small-12 columns">
                          {/* Print Frame */}
                          <label
                            htmlFor="print-material"
                            style={{ color: "#ffffff", fontSize: "12pt" }}
                          >
                            {translate("Frame:")}
                          </label>
                          <select
                            id="print-material"
                            onChange={handlePrintMaterialChange}
                          >
                            {printMaterials.map((material) => (
                              <option
                                key={material.value}
                                value={material.value}
                                style={{
                                  backgroundImage: `url(${material.icon})`,
                                }}
                              >
                                {material.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <p style={{ color: "#ddd", margin: 0, padding: 9 }}>
                            {translate("InfoAboutSize")}
                            {selectedPassepartout === "Yes"
                              ? currentSize
                              : selectedPrintSize}
                            {selectedPassepartout === "Yes"
                              ? translate("withPassepartout")
                              : translate("withoutPassepartout")}
                          </p>
                          <br />
                          <br />
                          <p
                            style={{
                              color: "#fff",
                              margin: 0,
                              textAlign: "center",
                              fontSize: "17pt",
                            }}
                          >
                            {translate("Price:")} {currentPrice}{" "}
                            {translate("SEK")}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Add to shoppingcart */}
                    {showDropdowns && (
                      <div
                        className="large-12 medium-12 small-12 columns"
                        style={{ textAlign: "center" }}
                      >
                        <p></p>
                        <button
                          className="order-button button success"
                          onClick={() => addtobasketClick(index)}
                        >
                          {translate("Add to shoppingcart")}
                        </button>
                      </div>
                    )}

                    {/* Close Button */}
                    <button
                      className="closebutton"
                      style={modalStyles.closeButton}
                      data-close
                      aria-label="Close modal"
                      type="button"
                      onClick={closeModal}
                    >
                      <span style={{ color: "#ffffff" }} aria-hidden="true">
                        &times;
                      </span>
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        ))}
      </div>

      {/* Slider view */}
      <>
        <div
          className={`off-canvas position-right ${
            isShoppingSliderOpen ? "is-open" : ""
          }`}
          id="offCanvasRight"
          data-off-canvas
          style={sliderContainerStyle}
          ref={sliderRef}
        >
          <ul
            className="vertical menu"
            data-accordion-menu
            style={{ marginTop: "30px" }}
          >
            <li
              className="active"
              style={{
                marginTop: "60px",
              }}
            >
              <a href="/" style={sliderYourShoppingcartStyle}>
                {translate("Your Shoppingcart")}
                <br />
              </a>
            </li>

            <li style={{ marginTop: "0px", textAlign: "left" }}>
              <br />
              <div>
                {/* Selected images & options */}
                {selectedImages.length === 0 && !isConfirmClicked ? (
                  <p style={sliderNoItemsStyle}>
                    {translate("No items added")}
                  </p>
                ) : (
                  <>
                    {selectedImages.map((image, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            style={{
                              color: "#878787",
                              fontSize: "18px",
                              marginRight: "5px",
                            }}
                          >
                            {translate("Photo")} {index + 1}:
                          </label>
                          <div
                            className="input-group2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}
                            data-field={`quantity-${index}`}
                          >
                            <input
                              type="button"
                              value="-"
                              className="button-minus"
                              onClick={() => decrementValue(index)}
                            />
                            <input
                              type="number"
                              step="1"
                              max=""
                              value={image.quantity}
                              name="quantity"
                              className="quantity-field"
                              onChange={(e) =>
                                updateQuantity(index, parseInt(e.target.value))
                              }
                            />
                            <input
                              type="button"
                              value="+"
                              className="button-plus"
                              onClick={() => incrementValue(index)}
                            />
                          </div>
                        </div>
                        {/* Slider img */}
                        <img
                          src={image.imageUrl}
                          alt={image.filename}
                          style={{
                            display: "block",
                            margin: "20px auto",
                            maxWidth: "80%",
                            border:
                              image.frame === "No" ? "none" : "6px solid black",
                          }}
                        />
                        {/* Table with dropdown options: Passepartout, Size, Frame and Price */}
                        <table style={sliderTableStyle}>
                          <tbody style={{ background: "transparent" }}>
                            <tr>
                              <td style={headerStyle}>{translate("Size")}</td>
                              <td style={headerStyle}>
                                {translate("Passepartout")}
                              </td>
                              <td style={headerStyle}>{translate("Frame")}</td>
                              <td style={headerStyle}>
                                {translate("QuantityShort")}
                              </td>
                              <td style={headerStyle}>
                                {translate("PricePerItem")}
                              </td>
                            </tr>
                            <tr style={{ background: "transparent" }}>
                              {selectedDropdownOptions[index] && (
                                <td style={itemStyle}>
                                  {selectedDropdownOptions[index].printSize}
                                </td>
                              )}
                              {selectedDropdownOptions[index] && (
                                <td style={itemStyle}>
                                  {selectedDropdownOptions[index].passepartout}
                                </td>
                              )}
                              {selectedDropdownOptions[index] && (
                                <td style={itemStyle}>
                                  {selectedDropdownOptions[index].printMaterial}
                                </td>
                              )}
                              <td style={itemStyle}>{image.quantity}</td>
                              <td style={itemStyle}>
                                {image.price} {translate("SEK")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <br />
                      </div>
                    ))}

                    {!isConfirmClicked ? (
                      <div>
                        <label
                          style={{
                            fontSize: "12pt",
                            color: "white",
                            textAlign: "left",
                            maxWidth: "90%",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={isLocalPickup}
                            onChange={(e) => setIsLocalPickup(e.target.checked)}
                          />
                          Jag hämtar själv på Tzettmarker Photography
                        </label>
                        <br />
                        <p style={sliderPriceStyle}>
                          {translate("TotalPriceForImages")}:{" "}
                          {currentTotalPrice} {translate("SEK")}
                        </p>

                        <p style={sliderDeliverycostStyle}>
                          {translate("DeliveryCost")}:{" "}
                          {calculateDeliveryCost(selectedDropdownOptions)}{" "}
                          {translate("SEK")}
                        </p>

                        <p style={sliderTotalPriceStyle}>
                          {translate("TotalAmountIncludingDelivery")}:{" "}
                          {currentTotalPrice +
                            calculateDeliveryCost(selectedDropdownOptions)}{" "}
                          {translate("SEK")}
                        </p>

                        <form
                          method="post"
                          action="https://tzettmarker.se/phpmailer/send_email.php"
                          acceptCharset="ISO-8859-1"
                          onSubmit={formSubmit}
                          id="order-form"
                          className="formula-text"
                        >
                          {/* Lägger till ett unikt dolt fält som anger formulärets 'typ' */}
                          <input
                            type="hidden"
                            name="formType"
                            value="sliderOrderForm"
                          />
                          <input
                            type="hidden"
                            name="isLocalPickup"
                            value={isLocalPickup ? "true" : "false"}
                          />
                          {translate("FirstName")}
                          <br />
                          {formErrors.firstname && (
                            <p className="red-warning-text">
                              {translate(formErrors.firstname)}
                            </p>
                          )}
                          <input name="firstname" />
                          <br />

                          {/* <!-- Efternamn --> */}
                          {translate("Surname")}
                          <br />
                          {formErrors.surname && (
                            <p className="red-warning-text">
                              {translate(formErrors.surname)}
                            </p>
                          )}
                          <input name="surname" />
                          <br />

                          {/* <!-- Gata --> */}
                          {translate("Street")}
                          <br />
                          {formErrors.street && (
                            <p className="red-warning-text">
                              {translate(formErrors.street)}
                            </p>
                          )}
                          <input name="street" />
                          <br />

                          {/* <!-- Postnummer och Stad --> */}
                          {translate("PostcodeCity")}
                          <br />
                          {formErrors.postcodeCity && (
                            <p className="red-warning-text">
                              {translate(formErrors.postcodeCity)}
                            </p>
                          )}
                          <input name="postcodeCity" />
                          <br />

                          {translate("Email")}
                          <br />
                          {formErrors.email && (
                            <p className="red-warning-text">
                              {translate(formErrors.email)}
                            </p>
                          )}
                          <input name="email" />
                          <br />
                          {translate("MessageOptionally")}
                          <br />
                          <textarea
                            cols="40"
                            rows="4"
                            name="Message"
                          ></textarea>
                          <br />
                          <button
                            type="submit"
                            id="confirm-button"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              fontSize: "12pt",
                              color: "#ffffff", // Vit färg
                              border: "none", // Ingen kantlinje
                            }}
                          >
                            {translate("Confirm Order")}
                          </button>
                          <input
                            type="hidden"
                            name="recipient"
                            value="support@tzettmarker.se"
                          />
                          <input type="hidden" name="subject" value="Subject" />
                          <input
                            type="hidden"
                            name="redirect"
                            value="http://www.tzettmarker.se/shop"
                          />
                          <input
                            type="hidden"
                            name="missing_fields_redirect"
                            value="http://www.tzettmarker.se/gallery"
                          />
                          <input
                            type="hidden"
                            name="required"
                            value="firstname,surname,street,postcodeCity,email"
                          />
                        </form>
                      </div>
                    ) : (
                      <p>
                        {translate("ThankYouAndConfirmEmail")}
                        <br />
                        <br />
                        {`${translate("IfYouDidntReveived")} `}
                        <a href={`mailto:${translate("supportEmail")}`}>
                          {translate("supportEmail")}
                        </a>
                      </p>
                    )}

                    {isServerError ? (
                      <p style={sliderServerErrorStyle}>
                        {translate("errorMessageBeforeEmail")}{" "}
                        <a href="mailto:support@tzettmarker.se">
                          support@tzettmarker.se
                        </a>
                      </p>
                    ) : null}
                  </>
                )}

                <input
                  type="button"
                  value="Close"
                  // onClick="window.close();"
                  style={{ display: "none" }}
                  id="closeButton"
                />
                <br />
                <br />
                <br />
                <br />
              </div>
            </li>
          </ul>
          {/* Close Button */}
          <button
            className="closebutton2"
            style={modalStyles.closeButton}
            data-close
            aria-label="Close modal"
            type="button"
            onClick={closeModal2}
          >
            <span style={{ color: "#ffffff" }} aria-hidden="true">
              &times;
            </span>
          </button>
        </div>
      </>

      {/* Shopping Cart */}
      <div style={shoppingCartStyle}>
        <img
          src={shoppingcart}
          alt="Cart Icon"
          id="cartIcon"
          width="40"
          height="40"
          onClick={handleCartIconClick}
        />
        <span id="cartCount" style={cartCountStyle}>
          {totalQuantity}
        </span>
      </div>
    </div>
  );
};

// Footer
const Footer = () => {
  // Hämta ScreenSizeContext from ScreenSizeProvider.js
  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  // State to keep track of the selected language
  const { selectedLanguage, handleLanguageChange, translate } = useLanguage();

  const footerContainerStyle = {
    bottom: "0px",
    borderTop: "4px solid #878787",
    marginTop: isMobileScreen
      ? isPortrait
        ? "15%"
        : "10%"
      : isTabletScreen
      ? "10%"
      : isSmallDesktopScreen
      ? "16%"
      : isMediumDesktopScreen
      ? "16%"
      : isLargeDesktopScreen
      ? "12%"
      : isExtraLargeDesktopScreen
      ? "10%"
      : "10%",
    paddingTop: "3rem",
    backgroundColor: "#282828",
  };
  const sectionsContainerStyle = {
    maxWidth: isReady
      ? isMobileScreen
        ? isPortrait
          ? "95%"
          : "90%"
        : isTabletScreen
        ? isPortrait
          ? "85%"
          : "85%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "85%"
          : "85%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "75%"
          : "80%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "75%"
          : "75%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "70%"
          : "70%"
        : "75%"
      : "75%",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const rightSectionStyle = {
    textAlign: isMobileScreen ? "left" : "right", // Ändra textjustering för mobilskärmar
    float: "left",
    width: isReady
      ? isMobileScreen
        ? isPortrait
          ? "100%"
          : "100%"
        : isTabletScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : "33.3%"
      : "33.3%",
  };
  const centerSectionStyle = {
    textAlign: isMobileScreen ? "left" : "center", // Ändra textjustering för mobilskärmar
    float: "left",
    width: isReady
      ? isMobileScreen
        ? isPortrait
          ? "100%"
          : "100%"
        : isTabletScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : "33.3%"
      : "33.3%",
  };
  const leftSectionStyle = {
    textAlign: isMobileScreen ? "left" : "left", // Ändra textjustering för mobilskärmar
    float: "left",
    width: isReady
      ? isMobileScreen
        ? isPortrait
          ? "100%"
          : "100%"
        : isTabletScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : "33.3%"
      : "33.3%",
  };
  const copyrightStyle = {
    textAlign: isMobileScreen ? "left" : "center", // Ändra textjustering för mobilskärmar
    marginLeft: isMobileScreen ? "1em" : "0em", // Ändra textjustering för mobilskärmar
    bottom: "1rem", // Använd önskat avstånd från botten
    color: "#444",
    padding: "1rem",
  };

  return (
    // Footer div
    <div style={footerContainerStyle}>
      {/* Sektioner-container */}
      <div style={sectionsContainerStyle}>
        {/* Sektionerna - innehållet */}
        <div style={leftSectionStyle}>
          <h3>{translate("Featured Sections")}</h3>
          <p>
            <a href="/">{translate("Home")}</a>
            <br />
            <a href="/gallery">{translate("Gallery")}</a>
            <br />
            <a href="/order">{translate("Order info")}</a>
            <br />
            <a href="/about">{translate("About")}</a>
            <br />
            <a href="/contact">{translate("Contact")}</a>
            <br />
          </p>
        </div>
        <div style={centerSectionStyle}>
          <h3>{translate("ContactTZETTMARKER")}</h3>
          <p>
            <a href="mailto:support@tzettmarker.se">support@tzettmarker.se</a>
          </p>
          <p>
            <a href="https://www.instagram.com/tzettmarker/">
              <img
                className="nav-logo-small"
                src={instagramIcon}
                alt="InstagramLogo"
              />
            </a>
          </p>
        </div>
        <div style={rightSectionStyle}>
          <h3>{translate("Our Website")}</h3>
          <p>
            <a href="/integrity">{translate("Privacy Policy")}</a>
            <br />
            <img
              className="nav-logo-small"
              src={swishLogo}
              alt="swishLogo"
              style={{ maxWidth: "2em", marginTop: "3em" }}
            />
          </p>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {/* Copyright */}
      <div style={copyrightStyle}>
        {translate("Website designed by")} {translate("Rebecka Zettmarker")}
        {" © "}
        {new Date().getFullYear()}
      </div>
    </div>
  );
};

const App = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  return (
    <ScreenSizeProvider>
      <div className="App">
        <Navigation />
        <Header />
        <Gallery />
        <OrderSummary selectedImages={selectedImages} />
        <Footer />
      </div>
    </ScreenSizeProvider>
  );
};

export default App;
