import React, { useEffect, useState, useRef, useContext } from "react";
import logoLiggande from "./images/Diverse/logo/LOGO_liggande2.png";
import GardientBackground from "./images/Diverse/logo/gradient_pattern2.png";
import instagramIcon from "./images/Diverse/Pilar-instagram-vattenstämpel/Instagram-Icon_small.png";
import swishLogo from "./images/Diverse/logo/SwishLogga.png";
import shoppingcart from "./images/Diverse/Pilar-instagram-vattenstämpel/shoppingcart2.png";
import { useLanguage, getTranslation } from "./LanguageProvider";
import { ScreenSizeProvider, ScreenSizeContext } from "./ScreenSizeProvider";
import english from "./images/Diverse/Pilar-instagram-vattenstämpel/english.jpg";
import swedish from "./images/Diverse/Pilar-instagram-vattenstämpel/swedish.jpg";
import "./css/fontfamily-OpenSansItalic.css";
import "./css/fontfamily-EBGaramound.css";
import "./css/slick.css";
import "./css/app2.css";
import palmtree from "./images/Diverse/Pilar-instagram-vattenstämpel/palmtree23.png";
import table from "./images/Diverse/Pilar-instagram-vattenstämpel/table-03.jpg";
import { validateForm } from "./validateForm.js";
import Cookies from "js-cookie";
import QRCode from "qrcode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay, faForward } from "@fortawesome/free-solid-svg-icons";

// Navigation component
const Navigation = () => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  // State to keep track of the selected language
  const { selectedLanguage, handleLanguageChange, translate } = useLanguage();

  const logoStyle = {
    maxWidth: isMobileScreen
      ? isPortrait
        ? "20vh"
        : "40vh"
      : isTabletScreen
      ? isPortrait
        ? "15vh"
        : "25vh"
      : isSmallDesktopScreen
      ? isPortrait
        ? "15vh"
        : "30vh"
      : isMediumDesktopScreen
      ? isPortrait
        ? "30vh"
        : "30vh"
      : isLargeDesktopScreen
      ? isPortrait
        ? "35vh"
        : "35vh"
      : isExtraLargeDesktopScreen
      ? isPortrait
        ? "30vh"
        : "30vh"
      : "30vh",
  };
  const languageButtonsStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px" /* Avstånd mellan knapparna */,
    height: "100%",
    float: "right",
    maxWidth: isReady
      ? isMobileScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isTabletScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : "1.4em"
      : "1.4em",
    marginRight: isReady
      ? isMobileScreen
        ? isPortrait
          ? "0em"
          : "-18em"
        : isTabletScreen
        ? isPortrait
          ? "-20em"
          : "-16em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-32em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-32em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-34em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-34em"
        : "-34em"
      : "-34em",
  };
  const mobileScreenNavigationStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100000,
    boxShadow: "3px 0px 5px #000",
    maxHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const navLogoContainerStyleMobile = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: "5%",
    float: "left",
  };
  const navLogoContainerStyleLarge = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    float: "left",
    marginLeft: "5%",
  };
  const largeNavigationRowStyle = {
    width: "auto",
    float: "right",
    marginRight: "12%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };
  const navigationButtonStyle = {
    color: "#d4be6a",
    fontSize: "1rem",
    lineHeight: "45px",
    margin: 0,
    height: "45px",
    padding: 0,
  };

  const toggleSlider = () => {
    setIsSliderOpen(!isSliderOpen);
  };

  const closeSlider = () => {
    setIsSliderOpen(false);
  };

  /* Handle click outside the Navigation-slider */
  useEffect(() => {
    const closeSlider = () => {
      setIsSliderOpen(false);
    };

    const handleClickOutside = (event) => {
      const offCanvas = document.getElementById("offCanvasRight");
      if (offCanvas && !offCanvas.contains(event.target)) {
        closeSlider();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Large Screen Navigation */}
      <div className="slide-nav show-for-large" style={{ top: 0 }}>
        {/* Wrap logo inside slide-nav */}
        <div style={navLogoContainerStyleLarge}>
          <a href="/">
            <img
              className="nav-logo"
              src={logoLiggande}
              alt="Logo"
              style={logoStyle}
            />
          </a>
        </div>

        <div className="row" style={largeNavigationRowStyle}>
          <div className="top-bar">
            <ul className="dropdown menu" data-dropdown-menu>
              <li>
                <a href="/">{translate("Home")}</a>
              </li>
              <li>
                <a href="/gallery">{translate("Gallery")}</a>
              </li>
              <li>
                <a href="/order">{translate("Order info")}</a>
              </li>
              <li>
                <a href="/about">{translate("About")}</a>
              </li>
              <li>
                <a href="/contact">{translate("Contact")}</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Språkval-knappar */}
        <div className="language-buttons" style={languageButtonsStyle}>
          <img
            src={swedish} // Lägg till sökväg till den svenska flaggan
            alt="Swedish"
            className="language-button"
            onClick={() => handleLanguageChange("sv")}
          />
          <img
            src={english} // Lägg till sökväg till den engelska flaggan
            alt="English"
            className="language-button"
            onClick={() => handleLanguageChange("en")}
          />
        </div>
      </div>

      {/* Mobile Screen Navigation */}
      <div
        className="slide-mobile-navigation hide-for-large"
        style={mobileScreenNavigationStyle}
      >
        {/* Wrap logo inside slide-mobile */}
        <div style={navLogoContainerStyleMobile}>
          <a href="/">
            <img
              className="nav-logo"
              src={logoLiggande}
              alt="Logo"
              style={logoStyle}
            />
          </a>
        </div>
        {/* Språkval-knappar */}
        <div className="language-buttons" style={languageButtonsStyle}>
          <img
            src={swedish}
            alt="Swedish"
            className="language-button"
            onClick={() => handleLanguageChange("sv")}
          />
          <img
            src={english}
            alt="English"
            className="language-button"
            onClick={() => handleLanguageChange("en")}
          />
        </div>

        {/* hamburger manu */}
        <div
          className="contain-to-grid"
          style={{ marginTop: "2.5px", marginBottom: "2.5px" }}
        >
          <div
            className="small-3 columns"
            style={{ float: "right", marginRight: "40%", width: "auto" }}
          >
            <div
              className="off-canvas-content"
              data-off-canvas-content
              style={{ float: "right" }}
            >
              <div className="nav-bar">
                <div className="nav-bar-right" style={{ float: "right" }}>
                  <ul className="menu">
                    <li>
                      <button
                        className="offcanvas-trigger"
                        type="button"
                        data-open="offCanvasRight"
                        aria-expanded={isSliderOpen ? "true" : "false"}
                        aria-controls="offCanvasRight"
                        style={navigationButtonStyle}
                        onClick={isSliderOpen ? closeSlider : toggleSlider} // Attach toggleSlider function to onClick event
                      >
                        <div className="hamburger" style={{ color: "#d4be6a" }}>
                          <span className="line"></span>
                          <span className="line"></span>
                          <span className="line"></span>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Meny-slidern */}
        <div
          className={`off-canvas position-right ${
            isSliderOpen ? "is-open" : ""
          }`}
          id="offCanvasRight"
          data-off-canvas
          style={{ zIndex: 1000000 }}
        >
          <ul
            className="vertical menu"
            data-accordion-menu
            style={{ marginTop: "30px" }}
          >
            <li className="active">
              <a href="/">{translate("Home")}</a>
            </li>
            <li className=" ">
              <a href="/gallery">{translate("Gallery")}</a>
            </li>
            <li className=" ">
              <a href="/order">{translate("Order info")}</a>
            </li>
            <li className=" ">
              <a href="/about">{translate("About")}</a>
            </li>
            <li className=" ">
              <a href="/contact">{translate("Contact")}</a>
            </li>
            <li style={{ marginTop: "30px", textAlign: "left" }}>
              <p>
                <a href="https://www.instagram.com/tzettmarker/">
                  <img
                    className="nav-logo-small-menu"
                    src={instagramIcon}
                    alt="Instagram"
                  />
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const OrderSummary = ({ selectedImages }) => {
  const numberOfSelectedImages = selectedImages.length;

  return (
    <div>
      <span className="basket-icon"></span>
      {numberOfSelectedImages > 0 && (
        <span className="red-count">{numberOfSelectedImages}</span>
      )}
    </div>
  );
};

const Home = ({
  liggandesjpgImages,
  currentQuote,
  changeQuote,
  isPaused,
  setIsPaused,
  getModalImageSrc,
}) => {
  // const [liggandesjpgImages, setliggandesjpgImages] = useState([]);
  // const [currentQuote, setCurrentQuote] = useState(0);
  const [konverterade1Images, setKonverterade1Images] = useState([]);
  const [konverterade2Images, setKonverterade2Images] = useState([]);
  const [openModalIndex, setOpenModalIndex] = useState(null);
  const [columnCount, setColumnCount] = useState(3);
  const [selectedPrintSize, setSelectedPrintSize] = useState("20x25");
  const [currentSize, setCurrentSize] = useState("14x19");
  const [selectedPassepartout, setSelectedPassepartout] = useState("Yes");
  const [selectedPrintMaterial, setSelectedPrintMaterial] = useState("Yes");
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [isShoppingSliderOpen, setIsShoppingSliderOpen] = useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDropdownOptions, setSelectedDropdownOptions] = useState([]);
  const sliderRef = useRef(null);
  const [modalImages, setModalImages] = useState([...konverterade1Images]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(500);
  const [quantity, setQuantity] = useState(1); // Antal tryck på plus/minus
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(currentPrice * quantity);
  const [isLocalPickup, setIsLocalPickup] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isServerError, setIsServerError] = useState(false);

  // Hämta ScreenSizeContext from ScreenSizeProvider.js
  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  let randomButtonBottom = "-10%";
  let pauseButtonBottom = "-10%";

  if (isMobileScreen) {
    randomButtonBottom = "-20%";
    pauseButtonBottom = "-20%";
  } else if (isTabletScreen) {
    randomButtonBottom = "-12%";
    pauseButtonBottom = "-12%";
  }

  const randomButtonStyle = {
    position: "absolute",
    cursor: "pointer",
    opacity: "0.3",
    bottom: randomButtonBottom,
    left: "14%",
  };

  const pauseButtonStyle = {
    position: "absolute",
    cursor: "pointer",
    opacity: "0.3",
    bottom: pauseButtonBottom,
    left: "2%",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    margin: "0",
  };

  const shoppingCartStyle = {
    position: "fixed",
    zIndex: 10000,
    top: isReady
      ? isMobileScreen
        ? isPortrait
          ? "4.8em"
          : "5.8em"
        : isTabletScreen
        ? isPortrait
          ? "6em"
          : "7em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "6em"
          : "7em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "7em"
          : "7em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "8em"
          : "8em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "8em"
          : "8em"
        : "7em"
      : "7em",
    right: isReady
      ? isMobileScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isTabletScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "2em"
          : "2em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "2em"
          : "2em"
        : "1em"
      : "1em",
  };
  const headerStyle = {
    border: "1px solid #000",
    padding: "4px",
    fontSize: "11pt",
    color: "#878787",
  };
  const itemStyle = {
    border: "1px solid #000",
    padding: "4px",
    fontSize: "11pt",
    color: "#fff",
  };
  const mainContentRowStyle = {
    marginTop: isReady
      ? isMobileScreen
        ? isPortrait
          ? "4.4em"
          : "3.5em"
        : isTabletScreen
        ? isPortrait
          ? "3.5em"
          : "3.5em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "3.5em"
          : "5.5em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "3.5em"
          : "6.5em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "3.5em"
          : "5.5em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "5.5em"
          : "5.5em"
        : "5.5em"
      : "5.5em",
  };
  const passepartoutStyle = {
    border: isReady
      ? isMobileScreen
        ? isPortrait
          ? "1.3em solid #fff"
          : "2.3em solid #fff"
        : isTabletScreen
        ? isPortrait
          ? "2.5em solid #fff"
          : "2em solid #fff"
        : isSmallDesktopScreen
        ? isPortrait
          ? "2.4em solid #fff"
          : "2.8em solid #fff"
        : isMediumDesktopScreen
        ? isPortrait
          ? "3em solid #fff"
          : "2.5em solid #fff"
        : isLargeDesktopScreen
        ? isPortrait
          ? "3em solid #fff"
          : "3em solid #fff"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "3em solid #fff"
          : "3em solid #fff"
        : "2em solid #fff"
      : "2em solid #fff",
  };
  const textenStyle = {
    textAlign: "center",
    marginBottom: isPortrait ? "20em" : "28em",
    fontSize: isReady
      ? isMobileScreen
        ? isPortrait
          ? "10.5pt"
          : "12.5pt"
        : isTabletScreen
        ? isPortrait
          ? "15pt"
          : "17pt"
        : isSmallDesktopScreen
        ? isPortrait
          ? "15pt"
          : "17pt"
        : isMediumDesktopScreen
        ? isPortrait
          ? "15pt"
          : "17pt"
        : isLargeDesktopScreen
        ? isPortrait
          ? "15pt"
          : "17pt"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "15pt"
          : "17pt"
        : "17pt"
      : "17pt",
  };
  const modalStyles = {
    shouldCloseOnOverlayClick: true,
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      position: "fixed",
      inset: 0,
      zIndex: 9999,
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pointerEvents: "none",
      columnCount: columnCount,
    },
    content: {
      overflow: "auto", // Enable scrolling if image exceeds container size
      maxHeight: "88vh", // Adjust the value as needed
      width: "100%",
      marginTop: isReady
        ? isMobileScreen
          ? isPortrait
            ? "15%"
            : "8%"
          : isTabletScreen
          ? isPortrait
            ? "8%"
            : "6%"
          : isSmallDesktopScreen
          ? isPortrait
            ? "8%"
            : "0%"
          : isMediumDesktopScreen
          ? isPortrait
            ? "10%"
            : "0%"
          : isLargeDesktopScreen
          ? isPortrait
            ? "15%"
            : "0%"
          : isExtraLargeDesktopScreen
          ? isPortrait
            ? "15%"
            : "0%"
          : "2%"
        : "0",
      background: "rgba(0, 0, 0, 0)",
      border: "none",
      position: "relative",
      inset: "0px",
      overflow: "auto",
      pointerEvents: "auto", // Enable pointer events on the content
      outline: "none",
      display: "flex",
      flexDirection: "column", // Change to "column" for vertical alignment
      alignItems: "center", // Horizontal alignment
    },
    imageContainer: {
      flex: "1 1 auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "auto",
      maxHeight: "80vh", // Adjust the value as needed
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "auto",
      height: "auto",
      objectFit: "contain", // Maintain the aspect ratio of the image
    },
    landscapeImage: {
      maxHeight: "80vh", // Adjust the value as needed for landscape images
    },
    portraitImage: {
      maxWidth: "80vw", // Adjust the value as needed for portrait images
    },
  };
  const cartCountStyle = {
    color: "red",
    fontSize: "28px",
    fontWeight: "bold",
    position: "relative",
    marginLeft: "-8px",
    top: "-8px",
  };
  const sliderContainerStyle = {
    zIndex: 1000000,
    width: isMobileScreen
      ? isPortrait
        ? "320px"
        : "350px"
      : isTabletScreen
      ? "400px"
      : isSmallDesktopScreen
      ? "400px"
      : isMediumDesktopScreen
      ? "400px"
      : isLargeDesktopScreen
      ? "450px"
      : isExtraLargeDesktopScreen
      ? "450px"
      : "350px",
    msTransform: isShoppingSliderOpen ? "translateX(0)" : "translateX(100%)",
    transform: isShoppingSliderOpen ? "translateX(0)" : "translateX(100%)",
  };
  const sliderTableStyle = {
    borderCollapse: "collapse",
    background: "transparent",
    width: "100%",
    marginBottom: "20px",
  };
  const sliderPriceStyle = {
    color: "#878787",
    fontSize: "12pt",
    margin: 0,
    padding: 0,
  };
  const sliderDeliverycostStyle = {
    color: "#878787",
    fontSize: "12pt",
    margin: 0,
    padding: 0,
  };
  const sliderTotalPriceStyle = {
    textAlign: "left",
    fontSize: "16pt",
    margin: 0,
    padding: 0,
    marginBottom: 50,
    color: "#ffffff",
  };
  const sliderConfirmButtonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    fontSize: "12pt",
    color: "#ffffff",
    border: "none",
  };
  const sliderServerErrorStyle = {
    color: "red",
    margin: "25px 0",
    textAlign: "center",
    fontSize: "medium",
  };
  const sliderNoItemsStyle = {
    color: "#878787",
    textAlign: "center",
    margin: "0 auto",
    marginTop: "20px",
  };
  const sliderYourShoppingcartStyle = {
    color: "#fff",
    fontSize: "20pt",
    textAlign: "center",
    marginTop: "40px",
  };
  const bigframeStyle = {
    margin: "0em",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  };
  if (isMobileScreen) {
    bigframeStyle.maxWidth = "90%";
    bigframeStyle.borderTop = "6px solid #000";
    bigframeStyle.borderBottom = "6px solid #444";
    bigframeStyle.borderRight = "6px solid #000";
    bigframeStyle.borderLeft = "6px solid #444";
    // Andra mobila stilar...
  } else if (isTabletScreen) {
    bigframeStyle.maxWidth = "80%";
    bigframeStyle.borderTop = "10px solid #000";
    bigframeStyle.borderBottom = "10px solid #444";
    bigframeStyle.borderRight = "10px solid #000";
    bigframeStyle.borderLeft = "10px solid #444";
    // Andra tablet-stilar...
  } else if (isSmallDesktopScreen) {
    bigframeStyle.maxWidth = "80%";
    bigframeStyle.borderTop = "10px solid #000";
    bigframeStyle.borderBottom = "10px solid #444";
    bigframeStyle.borderRight = "10px solid #000";
    bigframeStyle.borderLeft = "10px solid #444";
    // Andra små desktop-stilar...
  } else if (isMediumDesktopScreen) {
    bigframeStyle.maxWidth = "75%";
    bigframeStyle.borderTop = "11px solid #000";
    bigframeStyle.borderBottom = "11px solid #444";
    bigframeStyle.borderRight = "11px solid #000";
    bigframeStyle.borderLeft = "11px solid #444";
    // Andra medium desktop-stilar...
  } else if (isLargeDesktopScreen) {
    bigframeStyle.maxWidth = "70%";
    bigframeStyle.borderTop = "12px solid #000";
    bigframeStyle.borderBottom = "12px solid #444";
    bigframeStyle.borderRight = "12px solid #000";
    bigframeStyle.borderLeft = "12px solid #444";
    // Andra stora desktop-stilar...
  } else if (isExtraLargeDesktopScreen) {
    bigframeStyle.maxWidth = "50%";
    bigframeStyle.borderTop = "9px solid #000";
    bigframeStyle.borderBottom = "9px solid #444";
    bigframeStyle.borderRight = "9px solid #000";
    bigframeStyle.borderLeft = "9px solid #444";
    // Andra extra stora desktop-stilar...
  }

  // State to keep track of the selected language
  const { selectedLanguage, handleLanguageChange, translate } = useLanguage();

  const removeImageGroup = (index) => {
    const updatedSelectedImages = selectedImages.filter((_, i) => i !== index);
    const updatedDropdownOptions = selectedDropdownOptions.filter(
      (_, i) => i !== index
    );

    setSelectedImages(updatedSelectedImages);
    setSelectedDropdownOptions(updatedDropdownOptions);

    // Uppdatera cookies med de nya listorna
    Cookies.set("selectedImages", JSON.stringify(updatedSelectedImages));
    Cookies.set(
      "selectedDropdownOptions",
      JSON.stringify(updatedDropdownOptions)
    );
  };

  useEffect(() => {
    const savedSelectedImages = Cookies.get("selectedImages");
    const savedDropdownOptions = Cookies.get("selectedDropdownOptions");
    if (savedSelectedImages && savedDropdownOptions) {
      setSelectedImages(JSON.parse(savedSelectedImages));
      setSelectedDropdownOptions(JSON.parse(savedDropdownOptions));
    }
  }, []); // Använd tom beroende-array för att köra detta bara vid första renderingen

  /* Price change */
  useEffect(() => {
    setTotalPrice(currentPrice * quantity);
  }, [currentPrice, quantity]);
  /* Higher Quantity */
  const incrementValue = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages[index].quantity += 1;
    setSelectedImages(updatedImages);
    setTotalPrice(currentPrice * updatedImages[index].quantity);
  };
  /* Lower Quantity */
  const decrementValue = (index) => {
    const updatedImages = [...selectedImages];
    if (updatedImages[index].quantity > 1) {
      updatedImages[index].quantity -= 1;
      setSelectedImages(updatedImages);
      setTotalPrice(currentPrice * updatedImages[index].quantity);
    } else {
      removeImageGroup(index);
    }
  };

  /* After Confirm, reset quantity and price */
  const resetQuantityAndPrice = () => {
    setQuantity(1);
    setTotalPrice(currentPrice);
  };

  // Update the modalImages state when the selectedPassepartout changes
  useEffect(() => {
    const defaultModalImage =
      selectedPassepartout === "Yes"
        ? konverterade2Images
        : konverterade1Images;
    setModalImages(defaultModalImage);
  }, [selectedPassepartout, konverterade1Images, konverterade2Images]);

  useEffect(() => {
    let price = currentPrice;

    // Update price based on passepartout option
    if (selectedPassepartout === "No") {
      price -= 200;
    } else {
      price += 200;
    }

    setCurrentPrice(price);
  }, [selectedPassepartout]);

  const calculateDeliveryCost = (selectedOptions) => {
    let maxDeliveryCost = 0;

    if (isLocalPickup) return 0;

    // Loopa genom alla valda alternativ och hitta det högsta fraktpriset
    selectedOptions.forEach((option) => {
      const cost = getDeliveryCostForSize(option.printSize);
      if (cost > maxDeliveryCost) {
        maxDeliveryCost = cost;
      }
    });

    // Returnera det högsta fraktpriset som den totala fraktkostnaden
    return maxDeliveryCost;
  };

  // Funktion för att bestämma fraktkostnad baserat på storlek
  const getDeliveryCostForSize = (size) => {
    switch (size) {
      case "20x25":
        return 50;
      case "30x40":
        return 75;
      case "40x50":
        return 100;
      case "50x70":
        return 125;
      case "60x80":
      case "70x100":
        return 150;
      default:
        return 50; // Standardkostnad om storleken inte matchar någon av de ovan
    }
  };

  useEffect(() => {
    let price;

    // Set prices based on selected print size, passepartout, and frame
    if (selectedPrintSize === "20x25") {
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 300;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 400;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 450;
      } else {
        price = 500;
      }
    } else if (selectedPrintSize === "30x40") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 400;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 500;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 700;
      } else {
        price = 750;
      }
    } else if (selectedPrintSize === "40x50") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 500;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 650;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 800;
      } else {
        price = 900;
      }
    } else if (selectedPrintSize === "50x70") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 600;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 800;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 1100;
      } else {
        price = 1200;
      }
    } else if (selectedPrintSize === "60x80") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 700;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 950;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 1300;
      } else {
        price = 1400;
      }
    } else if (selectedPrintSize === "70x100") {
      // ... continue with other print sizes and combinations
      if (selectedPassepartout === "No" && selectedPrintMaterial === "No") {
        price = 800;
      } else if (
        selectedPassepartout === "Yes" &&
        selectedPrintMaterial === "No"
      ) {
        price = 1100;
      } else if (
        selectedPassepartout === "No" &&
        selectedPrintMaterial === "Yes"
      ) {
        price = 1700;
      } else {
        price = 1900;
      }
    }

    setCurrentPrice(price);
  }, [selectedPrintSize, selectedPassepartout, selectedPrintMaterial]);

  useEffect(() => {
    setTotalPrice(currentPrice * quantity);
  }, [currentPrice, quantity]);

  const closeModal = () => {
    document.body.style.overflow = "";
    setOpenModalIndex(null);
    setSelectedPassepartout("Yes");
    setCurrentSize("14x19 cm");
    setIsConfirmClicked(false);
    handleSliderClose();
  };

  const closeModal2 = () => {
    setIsShoppingSliderOpen();
  };

  /* Handle click outside the modal */
  useEffect(() => {
    const handleModalClickOutside = (event) => {
      const modalContainer = document.querySelector(
        `#galleryModal${openModalIndex + 1}`
      );
      const screenWidth = window.innerWidth;
      const rightBoundary = screenWidth * 0.95;

      if (
        modalContainer &&
        !modalContainer.contains(event.target) &&
        event.clientX < rightBoundary
      ) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleModalClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleModalClickOutside);
    };
  }, [openModalIndex]);

  // Hämta kundvagnsdata från cookies när komponenten laddas
  useEffect(() => {
    const savedSelectedImages = Cookies.get("selectedImages");
    if (savedSelectedImages) {
      setSelectedImages(JSON.parse(savedSelectedImages));
    }
  }, []);

  useEffect(() => {
    const savedSelectedImages = Cookies.get("selectedImages");
    const savedDropdownOptions = Cookies.get("selectedDropdownOptions");

    if (savedSelectedImages && savedDropdownOptions) {
      setSelectedImages(JSON.parse(savedSelectedImages));
      setSelectedDropdownOptions(JSON.parse(savedDropdownOptions));
    }
  }, []);

  const updateQuantity = (index, value) => {
    setSelectedImages((prevSelectedImages) => {
      const updatedImages = [...prevSelectedImages];
      updatedImages[index].quantity = value;
      return updatedImages;
    });
  };

  useEffect(() => {
    const newTotalQuantity = selectedImages.reduce(
      (total, image) => total + image.quantity,
      0
    );

    setTotalQuantity(newTotalQuantity);
    Cookies.set("selectedImages", JSON.stringify(selectedImages));
    Cookies.set(
      "selectedDropdownOptions",
      JSON.stringify(selectedDropdownOptions)
    );

    const newTotalPrice = currentPrice * newTotalQuantity;
    setTotalPrice(newTotalPrice);
  }, [selectedImages, selectedDropdownOptions]);

  const currentTotalPrice = selectedImages.reduce(
    (total, image) => total + image.price * image.quantity,
    0
  );

  /* Handle click outside the shopping-slider */
  useEffect(() => {
    const handleShoppingSliderClickOutside = (event) => {
      const slider = sliderRef.current;
      const screenWidth = window.innerWidth;
      const rightBoundary = screenWidth * 0.95;

      if (
        slider &&
        !slider.contains(event.target) &&
        !event.target.classList.contains("modal-image") &&
        event.clientX < rightBoundary
      ) {
        setIsShoppingSliderOpen(false);
      }
    };

    document.addEventListener("mousedown", handleShoppingSliderClickOutside);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleShoppingSliderClickOutside
      );
    };
  }, [openModalIndex]);

  const handleSliderClose = () => {
    if (!isConfirmClicked) {
      setIsSliderOpen(false);
    }
  };

  const handleCartIconClick = () => {
    setIsShoppingSliderOpen(true);
    setIsConfirmClicked(false); // Reset the confirmation status
  };

  const formSubmit = (event) => {
    event.preventDefault();

    const { isValid, formErrors } = validateForm(
      event.target.firstname.value,
      event.target.surname.value,
      event.target.street.value,
      event.target.postcodeCity.value,
      event.target.email.value,
      event.target.Message.value
    );

    if (!isValid) {
      setFormErrors(formErrors);
      return;
    }

    const form = event.target;
    const formData = new FormData(form);

    formData.append("selectedImages", JSON.stringify(selectedImages));
    formData.append(
      "selectedDropdownOptions",
      JSON.stringify(selectedDropdownOptions)
    );
    formData.append("quantity", quantity);
    formData.append("totalPrice", totalPrice);

    QRCode.toDataURL("Här är data som ska kodas", function (err, url) {
      if (err) {
        console.error(err);
        return;
      }

      formData.append("qrcode", url);

      // Placera fetch inuti callback-funktionen för QRCode.toDataURL
      fetch("https://tzettmarker.se/public/send_email.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            console.log(
              "Data Success. setIsSubmitted(true); och setIsServerError(false);"
            );
            // Om servern svarade med success: true
            setIsConfirmClicked(true);
            setSelectedImages([]);
            setSelectedDropdownOptions([]);
            resetQuantityAndPrice();
            setIsSubmitted(true);
            setIsServerError(false);
          } else {
            // Om servern svarade med success: false
            console.log(
              "Data Not Success. setIsSubmitted(true); och setIsServerError(true);"
            );
            setIsConfirmClicked(false);
            setIsSubmitted(true);
            setIsServerError(true);
          }
        })
        .catch((error) => {
          // Om något går fel med förfrågan (t.ex. nätverksfel)
          console.log("Fetch Error: ", error);
          setIsConfirmClicked(false);
          setIsSubmitted(false);
          setIsServerError(true);
        });
    });
  };

  return (
    <div>
      <div>
        <header>
          <div className="main-content-row" style={mainContentRowStyle}>
            <div>
              <h1 className="titleclass1">Thomas Zettmarker</h1>
              <h3 className="titleclass2">
                {translate("Nature & Landscape Photographer")}
              </h3>
            </div>
          </div>
          {/* Bakgrundsbilden */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(${GardientBackground})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 1,
              zIndex: -1,
            }}
          />

          <div style={bigframeStyle}>
            <a href="/gallery">
              <div className="passepartout" style={passepartoutStyle}>
                <div id="bilden" align="center">
                  <img
                    className="image-motion-blur"
                    src={getModalImageSrc()}
                    alt="Random Quote Image"
                    style={{ verticalAlign: "middle" }}
                  />
                </div>
              </div>
            </a>
            <div className="randombutton" style={randomButtonStyle}>
              <FontAwesomeIcon icon={faForward} onClick={changeQuote} />
            </div>
            <button
              style={pauseButtonStyle}
              onClick={() => setIsPaused(!isPaused)}
            >
              <FontAwesomeIcon icon={isPaused ? faPlay : faPause} />
            </button>
          </div>

          <div id="texten" style={textenStyle}>
            <em align="center">{liggandesjpgImages[currentQuote]?.text}</em>
          </div>
        </header>
      </div>

      {/* Slider view */}
      <>
        <div
          className={`off-canvas position-right ${
            isShoppingSliderOpen ? "is-open" : ""
          }`}
          id="offCanvasRight"
          data-off-canvas
          style={sliderContainerStyle}
          ref={sliderRef}
        >
          <ul
            className="vertical menu"
            data-accordion-menu
            style={{ marginTop: "30px" }}
          >
            <li
              className="active"
              style={{
                marginTop: "60px",
              }}
            >
              <a href="/" style={sliderYourShoppingcartStyle}>
                {translate("Your Shoppingcart")}
                <br />
              </a>
            </li>

            <li style={{ marginTop: "0px", textAlign: "left" }}>
              <br />
              <div>
                {/* Selected images & options */}
                {selectedImages.length === 0 && !isConfirmClicked ? (
                  <p style={sliderNoItemsStyle}>
                    {translate("No items added")}
                  </p>
                ) : (
                  <>
                    {selectedImages.map((image, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            style={{
                              color: "#878787",
                              fontSize: "18px",
                              marginRight: "5px",
                            }}
                          >
                            {translate("Photo")} {index + 1}:
                          </label>
                          <div
                            className="input-group2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}
                            data-field={`quantity-${index}`}
                          >
                            <input
                              type="button"
                              value="-"
                              className="button-minus"
                              onClick={() => decrementValue(index)}
                            />
                            <input
                              type="number"
                              step="1"
                              max=""
                              value={image.quantity}
                              name="quantity"
                              className="quantity-field"
                              onChange={(e) =>
                                updateQuantity(index, parseInt(e.target.value))
                              }
                            />
                            <input
                              type="button"
                              value="+"
                              className="button-plus"
                              onClick={() => incrementValue(index)}
                            />
                          </div>
                        </div>
                        {/* Slider img */}
                        <img
                          src={image.imageUrl}
                          alt={image.filename}
                          style={{
                            display: "block",
                            margin: "20px auto",
                            maxWidth: "80%",
                            border:
                              image.frame === "No" ? "none" : "6px solid black",
                          }}
                        />
                        {/* Table with dropdown options: Passepartout, Size, Frame and Price */}
                        <table style={sliderTableStyle}>
                          <tbody style={{ background: "transparent" }}>
                            <tr>
                              <td style={headerStyle}>{translate("Size")}</td>
                              <td style={headerStyle}>
                                {translate("Passepartout")}
                              </td>
                              <td style={headerStyle}>{translate("Frame")}</td>
                              <td style={headerStyle}>
                                {translate("Quantity")}
                              </td>
                              <td style={headerStyle}>
                                {translate("PricePerItem")}
                              </td>
                            </tr>
                            <tr style={{ background: "transparent" }}>
                              {selectedDropdownOptions[index] && (
                                <td style={itemStyle}>
                                  {selectedDropdownOptions[index].printSize}
                                </td>
                              )}
                              {selectedDropdownOptions[index] && (
                                <td style={itemStyle}>
                                  {selectedDropdownOptions[index].passepartout}
                                </td>
                              )}
                              {selectedDropdownOptions[index] && (
                                <td style={itemStyle}>
                                  {selectedDropdownOptions[index].printMaterial}
                                </td>
                              )}
                              <td style={itemStyle}>{image.quantity}</td>
                              <td style={itemStyle}>
                                {image.price} {translate("SEK")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <br />
                      </div>
                    ))}

                    {!isConfirmClicked ? (
                      <div>
                        <label
                          style={{
                            fontSize: "12pt",
                            color: "white",
                            textAlign: "left",
                            maxWidth: "90%",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={isLocalPickup}
                            onChange={(e) => setIsLocalPickup(e.target.checked)}
                          />
                          Jag hämtar själv på Tzettmarker Photography
                        </label>
                        <br />
                        <p style={sliderPriceStyle}>
                          {translate("TotalPriceForImages")}:{" "}
                          {currentTotalPrice} {translate("SEK")}
                        </p>

                        <p
                          style={{
                            color: "#878787",
                            fontSize: "12pt",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          {translate("DeliveryCost")}:{" "}
                          {calculateDeliveryCost(selectedDropdownOptions)}{" "}
                          {translate("SEK")}
                        </p>

                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "16pt",
                            margin: 0,
                            padding: 0,
                            marginBottom: 50,
                            color: "#ffffff", // eller en annan färg som du föredrar
                          }}
                        >
                          {translate("TotalAmountIncludingDelivery")}:{" "}
                          {currentTotalPrice +
                            calculateDeliveryCost(selectedDropdownOptions)}{" "}
                          {translate("SEK")}
                        </p>

                        <form
                          method="post"
                          action="https://tzettmarker.se/phpmailer/send_email.php"
                          acceptCharset="ISO-8859-1"
                          onSubmit={formSubmit}
                          id="order-form"
                          className="formula-text"
                        >
                          {/* Lägger till ett unikt dolt fält som anger formulärets 'typ' */}
                          <input
                            type="hidden"
                            name="formType"
                            value="sliderOrderForm"
                          />
                          <input
                            type="hidden"
                            name="isLocalPickup"
                            value={isLocalPickup ? "true" : "false"}
                          />
                          {translate("FirstName")}
                          <br />
                          {formErrors.firstname && (
                            <p className="red-warning-text">
                              {translate(formErrors.firstname)}
                            </p>
                          )}
                          <input name="firstname" />
                          <br />

                          {/* <!-- Efternamn --> */}
                          {translate("Surname")}
                          <br />
                          {formErrors.surname && (
                            <p className="red-warning-text">
                              {translate(formErrors.surname)}
                            </p>
                          )}
                          <input name="surname" />
                          <br />

                          {/* <!-- Gata --> */}
                          {translate("Street")}
                          <br />
                          {formErrors.street && (
                            <p className="red-warning-text">
                              {translate(formErrors.street)}
                            </p>
                          )}
                          <input name="street" />
                          <br />

                          {/* <!-- Postnummer och Stad --> */}
                          {translate("PostcodeCity")}
                          <br />
                          {formErrors.postcodeCity && (
                            <p className="red-warning-text">
                              {translate(formErrors.postcodeCity)}
                            </p>
                          )}
                          <input name="postcodeCity" />
                          <br />

                          {translate("Email")}
                          <br />
                          {formErrors.email && (
                            <p className="red-warning-text">
                              {translate(formErrors.email)}
                            </p>
                          )}
                          <input name="email" />
                          <br />
                          {translate("MessageOptionally")}
                          <br />
                          <textarea
                            cols="40"
                            rows="4"
                            name="Message"
                          ></textarea>
                          <br />
                          <button
                            type="submit"
                            id="confirm-button"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              fontSize: "12pt",
                              color: "#ffffff", // Vit färg
                              border: "none", // Ingen kantlinje
                            }}
                          >
                            {translate("Confirm Order")}
                          </button>
                          <input
                            type="hidden"
                            name="recipient"
                            value="support@tzettmarker.se"
                          />
                          <input type="hidden" name="subject" value="Subject" />
                          <input
                            type="hidden"
                            name="redirect"
                            value="http://www.tzettmarker.se/shop"
                          />
                          <input
                            type="hidden"
                            name="missing_fields_redirect"
                            value="http://www.tzettmarker.se/gallery"
                          />
                          <input
                            type="hidden"
                            name="required"
                            value="firstname,surname,street,postcodeCity,email"
                          />
                        </form>
                      </div>
                    ) : (
                      <p>
                        {translate("ThankYouAndConfirmEmail")}
                        <br />
                        <br />
                        {`${translate("IfYouDidntReveived")} `}
                        <a href={`mailto:${translate("supportEmail")}`}>
                          {translate("supportEmail")}
                        </a>
                      </p>
                    )}
                  </>
                )}

                <input
                  type="button"
                  value="Close"
                  // onClick="window.close();"
                  style={{ display: "none" }}
                  id="closeButton"
                />
                <br />
                <br />
                <br />
                <br />
              </div>
            </li>
          </ul>
          {/* Close Button */}
          <button
            className="closebutton2"
            style={modalStyles.closeButton}
            data-close
            aria-label="Close modal"
            type="button"
            onClick={closeModal2}
          >
            <span style={{ color: "#ffffff" }} aria-hidden="true">
              &times;
            </span>
          </button>
        </div>
      </>

      {/* Shopping Cart */}
      <div style={shoppingCartStyle}>
        <img
          src={shoppingcart}
          alt="Cart Icon"
          id="cartIcon"
          width="40"
          height="40"
          onClick={handleCartIconClick}
        />
        <span id="cartCount" style={cartCountStyle}>
          {totalQuantity}
        </span>
      </div>
    </div>
  );
};

// Footer
const Footer = ({ getModalImageSrc1, getModalImageSrc2 }) => {
  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  // State to keep track of the selected language
  const { selectedLanguage, handleLanguageChange, translate } = useLanguage();

  const footerContainerStyle = {
    bottom: "0px",
    paddingTop: "2em",
    backgroundColor: "#282828",
  };
  const sectionsContainerStyle = {
    maxWidth: isReady
      ? isMobileScreen
        ? isPortrait
          ? "95%"
          : "90%"
        : isTabletScreen
        ? isPortrait
          ? "85%"
          : "85%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "85%"
          : "85%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "75%"
          : "80%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "75%"
          : "75%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "70%"
          : "70%"
        : "75%"
      : "75%",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "3%",
  };
  const rightSectionStyle = {
    textAlign: isMobileScreen ? "left" : "right", // Ändra textjustering för mobilskärmar
    float: "left",
    width: isReady
      ? isMobileScreen
        ? isPortrait
          ? "100%"
          : "100%"
        : isTabletScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : "33.3%"
      : "33.3%",
  };
  const centerSectionStyle = {
    textAlign: isMobileScreen ? "left" : "center", // Ändra textjustering för mobilskärmar
    float: "left",
    width: isReady
      ? isMobileScreen
        ? isPortrait
          ? "100%"
          : "100%"
        : isTabletScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : "33.3%"
      : "33.3%",
  };
  const leftSectionStyle = {
    textAlign: isMobileScreen ? "left" : "left", // Ändra textjustering för mobilskärmar
    float: "left",
    width: isReady
      ? isMobileScreen
        ? isPortrait
          ? "100%"
          : "100%"
        : isTabletScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : "33.3%"
      : "33.3%",
  };
  const copyrightStyle = {
    textAlign: isMobileScreen ? "left" : "center", // Ändra textjustering för mobilskärmar
    marginLeft: isMobileScreen ? "1em" : "0em", // Ändra textjustering för mobilskärmar
    bottom: "1rem", // Använd önskat avstånd från botten
    color: "#444",
    padding: "1rem",
  };

  const passepartoutStyle = {
    border: isReady
      ? isMobileScreen
        ? isPortrait
          ? "0.4em solid #fff"
          : "0.7em solid #fff"
        : isTabletScreen
        ? isPortrait
          ? "0.75em solid #fff"
          : "0.5em solid #fff"
        : isSmallDesktopScreen
        ? isPortrait
          ? "0.8em solid #fff"
          : "1em solid #fff"
        : isMediumDesktopScreen
        ? isPortrait
          ? "1.2em solid #fff"
          : "1em solid #fff"
        : isLargeDesktopScreen
        ? isPortrait
          ? "1.4em solid #fff"
          : "1.4em solid #fff"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "1.4em solid #fff"
          : "1.4em solid #fff"
        : "1em solid #fff"
      : "1em solid #fff",
  };

  const tableLine = {
    width: "100%", // Använd 100% av footerns bredd
    position: "absolute",
    bottom: "0px",
    display: "block", // Ta bort extra utrymme under bilden
    backgroundColor: "transparent",
    boxShadow: isMobileScreen
      ? isPortrait
        ? "0 6px 6px rgba(0,0,0,0.5)"
        : "0 6px 8px rgba(0,0,0,0.5)"
      : isTabletScreen
      ? "0 6px 10px rgba(0,0,0,0.5)"
      : "0 8px 10px rgba(0,0,0,0.5)",
  };
  const footerWrapperStyle = {
    backgroundColor: "#333", // Ändra till den bakgrundsfärg du vill ha för footern
    position: "relative", // Så att allt inuti är relaterat till denna wrapper
  };
  const palmtreeStyle = {
    zIndex: 10,
    left: "65%",
    maxWidth: "27%",
    display: "block",
    bottom: isPortrait ? "4vh" : "9vh",
    position:
      "absolute" /* Positioneras i förhållande till närmaste position:relative förälder */,
    background: "transparent",
  };

  const bigframe2Style = {};
  const bigframe3Style = {};

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const isSmall =
    (isPortrait && screenWidth <= 400) || (!isPortrait && screenHeight <= 400);

  function setCommonStyles(frameStyle, transformAngle, boxShadowDirection) {
    frameStyle.transform = `perspective(1000px) rotateY(${transformAngle}deg)`;
    frameStyle.position = "absolute";
    frameStyle.bottom = isPortrait ? "4vh" : "15vh";
    frameStyle.boxShadow = `${boxShadowDirection}px -1px 15px 3px rgba(0, 0, 0, 0.3)`;
  }

  if (isMobileScreen) {
    const boxShadowDirection2 = isSmall ? 2 : 4;
    const boxShadowDirection3 = isSmall ? -2 : -4;

    bigframe2Style.maxWidth = isSmall ? "25%" : "23%";
    bigframe2Style.marginLeft = "35%";
    bigframe2Style.borderTop = "3.5px solid #000";
    bigframe2Style.borderBottom = "3.5px solid #444";
    bigframe2Style.borderRight = "3.5px solid #000";
    bigframe2Style.borderLeft = "3.5px solid #444";
    setCommonStyles(bigframe2Style, -15, boxShadowDirection2);
    // Ändrar skuggstorlek för mindre skärmar
    bigframe2Style.boxShadow = `${boxShadowDirection2}px -1px 10px 0px rgba(0, 0, 0, 0.5)`;

    bigframe3Style.maxWidth = isSmall ? "20%" : "18%";
    bigframe3Style.marginLeft = "5%";
    bigframe3Style.borderTop = "3.5px solid #000";
    bigframe3Style.borderBottom = "3.5px solid #444";
    bigframe3Style.borderRight = "3.5px solid #444";
    bigframe3Style.borderLeft = "3.5px solid #000";
    setCommonStyles(bigframe3Style, 15, boxShadowDirection3);
    // Ändrar skuggstorlek för mindre skärmar
    bigframe3Style.boxShadow = `${boxShadowDirection3}px -1px 10px 0px rgba(0, 0, 0, 0.5)`;
  } else if (
    isTabletScreen ||
    isSmallDesktopScreen ||
    isMediumDesktopScreen ||
    isLargeDesktopScreen ||
    isExtraLargeDesktopScreen
  ) {
    bigframe2Style.maxWidth = "23%";
    bigframe2Style.marginLeft = "35%";
    bigframe2Style.borderTop = "6px solid #000";
    bigframe2Style.borderBottom = "6px solid #444";
    bigframe2Style.borderRight = "6px solid #000";
    bigframe2Style.borderLeft = "6px solid #444";
    setCommonStyles(bigframe2Style, -15, 20);

    bigframe3Style.maxWidth = "18%";
    bigframe3Style.marginLeft = "5%";
    bigframe3Style.borderTop = "6px solid #000";
    bigframe3Style.borderBottom = "6px solid #444";
    bigframe3Style.borderRight = "6px solid #444";
    bigframe3Style.borderLeft = "6px solid #000";
    setCommonStyles(bigframe3Style, 15, -20);
  }

  return (
    // Footer div
    <div>
      <div style={footerWrapperStyle}>
        <img src={table} style={tableLine} alt="Table Line" />
        <div style={palmtreeStyle}>
          <img src={palmtree} alt="Palm Tree" />
        </div>

        <div>
          <div style={bigframe3Style}>
            <a href="/gallery">
              <div className="passepartout" style={passepartoutStyle}>
                <div id="bilden" align="center">
                  <img
                    className="image-motion-blur"
                    src={getModalImageSrc2()}
                    alt="Random Quote Image"
                    style={{ verticalAlign: "middle" }}
                  />
                </div>
              </div>
            </a>
          </div>
          <div style={bigframe2Style}>
            <a href="/gallery">
              <div className="passepartout" style={passepartoutStyle}>
                <div id="bilden" align="center">
                  <img
                    className="image-motion-blur"
                    src={getModalImageSrc1()}
                    alt="Random Quote Image"
                    style={{ verticalAlign: "middle" }}
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div style={footerContainerStyle}>
        {/* Sektioner-container */}
        <div style={sectionsContainerStyle}>
          {/* Sektionerna - innehållet */}
          <div style={leftSectionStyle}>
            <h3>{translate("Featured Sections")}</h3>
            <p>
              <a href="/">{translate("Home")}</a>
              <br />
              <a href="/gallery">{translate("Gallery")}</a>
              <br />
              <a href="/order">{translate("Order info")}</a>
              <br />
              <a href="/about">{translate("About")}</a>
              <br />
              <a href="/contact">{translate("Contact")}</a>
              <br />
            </p>
          </div>
          <div style={centerSectionStyle}>
            <h3>{translate("ContactTZETTMARKER")}</h3>
            <p>
              <a href="mailto:support@tzettmarker.se">support@tzettmarker.se</a>
            </p>
            <p>
              <a href="https://www.instagram.com/tzettmarker/">
                <img
                  className="nav-logo-small"
                  src={instagramIcon}
                  alt="InstagramLogo"
                />
              </a>
            </p>
          </div>
          <div style={rightSectionStyle}>
            <h3>{translate("Our Website")}</h3>
            <p>
              <a href="/integrity">{translate("Privacy Policy")}</a>
              <br />
              <img
                className="nav-logo-small"
                src={swishLogo}
                alt="swishLogo"
                style={{ maxWidth: "2em", marginTop: "3em" }}
              />
            </p>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        {/* Copyright */}
        <div style={copyrightStyle}>
          {translate("Website designed by")} {translate("Rebecka Zettmarker")}
          {" © "}
          {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [staendesjpgImages, setstaendesjpgImages] = useState([]);
  const [liggandesjpgImages, setliggandesjpgImages] = useState([]);
  const [currentQuote, setCurrentQuote] = useState(null);
  const [currentQuote1, setCurrentQuote1] = useState(null);
  const [currentQuote2, setCurrentQuote2] = useState(null);
  const [isPaused, setIsPaused] = useState(false);

  // Fetch images
  useEffect(() => {
    document.title = "Thomas Zettmarker Photography";
    const imageCaptions = [
      { filename: "1.jpg", caption: "Axeltorps Skogar, 2015" },
      { filename: "2.jpg", caption: "Hovs Hallar, 2015" },
      { filename: "3.jpg", caption: "Vällinge, 2009" },
      { filename: "4.jpg", caption: "Vällinge, 2009" },
      { filename: "5.jpg", caption: "Flottsbro, 2009" },
      { filename: "6.jpg", caption: "Flottsbro, 2009" },
      { filename: "7.jpg", caption: "Flottsbro, 2009" },
      { filename: "8.jpg", caption: "Gömmaren, 2010" },
      { filename: "9.jpg", caption: "Gömmaren, 2010" },
      { filename: "10.jpg", caption: "Fårö, 2017" },
      { filename: "11.jpg", caption: "Fårö, 2017" },
      { filename: "12.jpg", caption: "Tyresta Nationalpark, 2010" },
      { filename: "13.jpg", caption: "Tyresta Nationalpark, 2010" },
      { filename: "14.jpg", caption: "Tyresta Nationalpark, 2010" },
      { filename: "15.jpg", caption: "Ängsö, 2010" },
      { filename: "16.jpg", caption: "Abisko, 2011" },
      { filename: "17.jpg", caption: "Abisko, 2011" },
      { filename: "18.jpg", caption: "Flottsbro, 2011" },
      { filename: "19.jpg", caption: "Söderåsen, 2012" },
      { filename: "20.jpg", caption: "Kullaberg, 2012" },
      { filename: "21.jpg", caption: "Kullaberg, 2012" },
      { filename: "22.jpg", caption: "Kullaberg, 2012" },
      { filename: "23.jpg", caption: "Kullaberg, 2012" },
      { filename: "24.jpg", caption: "Kullaberg, 2012" },
      { filename: "25.jpg", caption: "Torö Stenstrand, 2012" },
      { filename: "26.jpg", caption: "Vällinge, 2012" },
      { filename: "27.jpg", caption: "Gålö, 2013" },
      { filename: "28.jpg", caption: "Gålö, 2013" },
      { filename: "29.jpg", caption: "Oxelösund, 2013" },
      { filename: "30.jpg", caption: "Öland, 2013" },
      { filename: "31.jpg", caption: "Öland, 2013" },
      { filename: "32.jpg", caption: "Öland, 2013" },
      { filename: "33.jpg", caption: "Öland, 2013" },
      { filename: "34.jpg", caption: "Öland, 2013" },
      { filename: "35.jpg", caption: "Öland, 2013" },
      { filename: "36.jpg", caption: "Öland, 2013" },
      { filename: "37.jpg", caption: "Öland, 2013" },
      { filename: "38.jpg", caption: "Öland, 2013" },
      { filename: "39.jpg", caption: "Öland, 2013" },
      { filename: "40.jpg", caption: "Öland, 2013" },
      { filename: "41.jpg", caption: "Öland, 2013" },
      { filename: "42.jpg", caption: "Öland, 2013" },
      { filename: "43.jpg", caption: "Landsort, 2013" },
      { filename: "44.jpg", caption: "Landsort, 2013" },
      { filename: "45.jpg", caption: "Landsort, 2013" },
      { filename: "46.jpg", caption: "Landsort, 2013" },
      { filename: "47.jpg", caption: "Landsort, 2013" },
      { filename: "48.jpg", caption: "Landsort, 2013" },
      { filename: "49.jpg", caption: "Landsort, 2013" },
      { filename: "50.jpg", caption: "Bohuskusten, 2014" },
      { filename: "51.jpg", caption: "Bohuskusten, 2014" },
      { filename: "52.jpg", caption: "Bohuskusten, 2014" },
      { filename: "53.jpg", caption: "Bohuskusten, 2014" },
      { filename: "54.jpg", caption: "Bohuskusten, 2014" },
      { filename: "55.jpg", caption: "Söderåsen, 2014" },
      { filename: "56.jpg", caption: "Söderåsen, 2014" },
      { filename: "57.jpg", caption: "Kullaberg, 2014" },
    ];

    const fetchImages = async () => {
      try {
        const liggandesjpgContext = require.context(
          "./images/Diverse/liggandes-jpg",
          false,
          /\.(jpg|jpeg)$/
        );
        const staendesjpgContext = require.context(
          "./images/Diverse/staendes-jpg",
          false,
          /\.(jpg|jpeg)$/
        );

        const liggandesjpgFilenames = liggandesjpgContext.keys();
        const staendesjpgFilenames = staendesjpgContext.keys();

        const liggandesjpgPromises = liggandesjpgFilenames.map((filename) =>
          import(`./images/Diverse/liggandes-jpg/${filename.slice(2)}`)
        );
        const staendesjpgPromises = staendesjpgFilenames.map((filename) =>
          import(`./images/Diverse/staendes-jpg/${filename.slice(2)}`)
        );

        const liggandesjpgModules = await Promise.all(liggandesjpgPromises);
        const staendesjpgModules = await Promise.all(staendesjpgPromises);

        const liggandesjpgLoadedImages = liggandesjpgModules.map(
          (module, index) => {
            const filename = liggandesjpgFilenames[index].substring(
              liggandesjpgFilenames[index].lastIndexOf("/") + 1
            );
            const src = module.default;

            // Find the corresponding caption for the current filename
            const imageCaptionObj = imageCaptions.find(
              (captionObj) => captionObj.filename === filename
            );

            // Use a default caption if no matching entry is found
            const defaultCaption = "Default Caption";
            const text = imageCaptionObj
              ? imageCaptionObj.caption
              : defaultCaption;

            return {
              filename,
              src,
              text,
            };
          }
        );
        const staendesjpgLoadedImages = staendesjpgModules.map(
          (module, index) => {
            const src = module.default;
            return {
              src,
            };
          }
        );

        setliggandesjpgImages(liggandesjpgLoadedImages);
        setstaendesjpgImages(staendesjpgLoadedImages);

        // Randomly set the initial currentQuote
        const randomIndex = Math.floor(
          Math.random() * liggandesjpgLoadedImages.length
        );
        const randomIndex1 = Math.floor(
          Math.random() * liggandesjpgLoadedImages.length
        );
        const randomIndex2 = Math.floor(
          Math.random() * staendesjpgLoadedImages.length
        );
        setCurrentQuote(randomIndex);
        setCurrentQuote1(randomIndex1);
        setCurrentQuote2(randomIndex2);
      } catch (error) {
        console.error("Error loading images:", error);
        setliggandesjpgImages([]);
        setstaendesjpgImages([]);
      }
    };
    fetchImages();
  }, []);

  // set Interval
  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        changeQuote();
      }, 10000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [currentQuote, currentQuote1, currentQuote2, isPaused]);

  //  make random
  const changeQuote = () => {
    let randomIndex = currentQuote;
    let randomIndex1 = currentQuote1;
    let randomIndex2 = currentQuote2;
    while (randomIndex === currentQuote) {
      randomIndex = Math.floor(Math.random() * liggandesjpgImages.length);
    }
    while (randomIndex1 === currentQuote1) {
      randomIndex1 = Math.floor(Math.random() * liggandesjpgImages.length);
    }
    while (randomIndex2 === currentQuote2) {
      randomIndex2 = Math.floor(Math.random() * staendesjpgImages.length);
    }
    setCurrentQuote(randomIndex);
    setCurrentQuote1(randomIndex1);
    setCurrentQuote2(randomIndex2);
  };

  // Motion blur effect
  useEffect(() => {
    const imageElements = document.querySelectorAll(".image-motion-blur");
    imageElements.forEach((imageElement) => {
      imageElement.classList.add("fade-out");
      imageElement.style.filter = "blur(0)";
      imageElement.style.transition = "filter 0.5s ease-in-out";

      setTimeout(() => {
        imageElement.style.filter = "blur(5px)";
        setTimeout(() => {
          imageElement.style.filter = "blur(0)";
          imageElement.classList.remove("fade-out");
          imageElement.classList.add("fade-in");
        }, 800);
      }, 1);
    });
  }, [currentQuote, currentQuote1, currentQuote2]);

  const getModalImageSrc = () => {
    const image = liggandesjpgImages[currentQuote];
    if (image) {
      // Image from liggandesjpgImages folder
      return image.src;
    }
  };

  const getModalImageSrc1 = () => {
    const image = liggandesjpgImages[currentQuote1];
    if (image) {
      // Image from liggandesjpgImages folder
      return image.src;
    }
  };

  const getModalImageSrc2 = () => {
    const image = staendesjpgImages[currentQuote2];
    if (image) {
      // Image from staendesjpgImages folder
      return image.src;
    }
  };

  return (
    <ScreenSizeProvider>
      <div className="App">
        <Navigation />
        <Home
          liggandesjpgImages={liggandesjpgImages}
          currentQuote={currentQuote}
          currentQuote1={currentQuote1}
          currentQuote2={currentQuote2}
          changeQuote={changeQuote}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
          getModalImageSrc={getModalImageSrc}
        />
        <OrderSummary selectedImages={selectedImages} />
        <Footer
          liggandesjpgImages={liggandesjpgImages}
          staendesjpgImages={staendesjpgImages}
          currentQuote={currentQuote}
          currentQuote1={currentQuote1}
          currentQuote2={currentQuote2}
          changeQuote={changeQuote}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
          getModalImageSrc1={getModalImageSrc1}
          getModalImageSrc2={getModalImageSrc2}
        />
      </div>
    </ScreenSizeProvider>
  );
};

export default App;
