import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import logoLiggande from "./images/Diverse/logo/LOGO_liggande2.png";
import GardientBackground from "./images/Diverse/logo/gradient_pattern2.png";
import instagramIcon from "./images/Diverse/Pilar-instagram-vattenstämpel/Instagram-Icon_small.png";
import swishLogo from "./images/Diverse/logo/SwishLogga.png";
import { useLanguage, getTranslation } from "./LanguageProvider";
import { ScreenSizeProvider, ScreenSizeContext } from "./ScreenSizeProvider";
import english from "./images/Diverse/Pilar-instagram-vattenstämpel/english.jpg";
import swedish from "./images/Diverse/Pilar-instagram-vattenstämpel/swedish.jpg";
import headerImage from "./images/Diverse/headers/Flottsbro_header.jpg";
import "./css/slick.css";
import "./css/app2.css";

// Navigation component
const Navigation = () => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  // State to keep track of the selected language
  const { selectedLanguage, handleLanguageChange, translate } = useLanguage();

  const logoStyle = {
    maxWidth: isMobileScreen
      ? isPortrait
        ? "20vh"
        : "40vh"
      : isTabletScreen
      ? isPortrait
        ? "15vh"
        : "25vh"
      : isSmallDesktopScreen
      ? isPortrait
        ? "15vh"
        : "30vh"
      : isMediumDesktopScreen
      ? isPortrait
        ? "30vh"
        : "30vh"
      : isLargeDesktopScreen
      ? isPortrait
        ? "35vh"
        : "35vh"
      : isExtraLargeDesktopScreen
      ? isPortrait
        ? "30vh"
        : "30vh"
      : "30vh",
  };
  const languageButtonsStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px" /* Avstånd mellan knapparna */,
    height: "100%",
    float: "right",
    maxWidth: isReady
      ? isMobileScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isTabletScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "1.4em"
          : "1.4em"
        : "1.4em"
      : "1.4em",
    marginRight: isReady
      ? isMobileScreen
        ? isPortrait
          ? "0em"
          : "-18em"
        : isTabletScreen
        ? isPortrait
          ? "-20em"
          : "-16em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-32em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-32em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-34em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "-22em"
          : "-34em"
        : "-34em"
      : "-34em",
  };
  const mobileScreenNavigationStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100000,
    boxShadow: "3px 0px 5px #000",
    maxHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const navLogoContainerStyleMobile = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: "5%",
    float: "left",
  };
  const navLogoContainerStyleLarge = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    float: "left",
    marginLeft: "5%",
  };
  const largeNavigationRowStyle = {
    width: "auto",
    float: "right",
    marginRight: "12%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };
  const navigationButtonStyle = {
    color: "#d4be6a",
    fontSize: "1rem",
    lineHeight: "45px",
    margin: 0,
    height: "45px",
    padding: 0,
  };

  const toggleSlider = () => {
    setIsSliderOpen(!isSliderOpen);
  };

  const closeSlider = () => {
    setIsSliderOpen(false);
  };

  /* Handle click outside the Navigation-slider */
  useEffect(() => {
    const closeSlider = () => {
      setIsSliderOpen(false);
    };

    const handleClickOutside = (event) => {
      const offCanvas = document.getElementById("offCanvasRight");
      if (offCanvas && !offCanvas.contains(event.target)) {
        closeSlider();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Large Screen Navigation */}
      <div className="slide-nav show-for-large" style={{ top: 0 }}>
        {/* Wrap logo inside slide-nav */}
        <div style={navLogoContainerStyleLarge}>
          <a href="/">
            <img
              className="nav-logo"
              src={logoLiggande}
              alt="Logo"
              style={logoStyle}
            />
          </a>
        </div>

        <div className="row" style={largeNavigationRowStyle}>
          <div className="top-bar">
            <ul className="dropdown menu" data-dropdown-menu>
              <li>
                <a href="/">{translate("Home")}</a>
              </li>
              <li>
                <a href="/gallery">{translate("Gallery")}</a>
              </li>
              <li>
                <a href="/order">{translate("Order info")}</a>
              </li>
              <li>
                <a href="/about">{translate("About")}</a>
              </li>
              <li>
                <a href="/contact">{translate("Contact")}</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Språkval-knappar */}
        <div className="language-buttons" style={languageButtonsStyle}>
          <img
            src={swedish} // Lägg till sökväg till den svenska flaggan
            alt="Swedish"
            className="language-button"
            onClick={() => handleLanguageChange("sv")}
          />
          <img
            src={english} // Lägg till sökväg till den engelska flaggan
            alt="English"
            className="language-button"
            onClick={() => handleLanguageChange("en")}
          />
        </div>
      </div>

      {/* Mobile Screen Navigation */}
      <div
        className="slide-mobile-navigation hide-for-large"
        style={mobileScreenNavigationStyle}
      >
        {/* Wrap logo inside slide-mobile */}
        <div style={navLogoContainerStyleMobile}>
          <a href="/">
            <img
              className="nav-logo"
              src={logoLiggande}
              alt="Logo"
              style={logoStyle}
            />
          </a>
        </div>
        {/* Språkval-knappar */}
        <div className="language-buttons" style={languageButtonsStyle}>
          <img
            src={swedish}
            alt="Swedish"
            className="language-button"
            onClick={() => handleLanguageChange("sv")}
          />
          <img
            src={english}
            alt="English"
            className="language-button"
            onClick={() => handleLanguageChange("en")}
          />
        </div>

        {/* hamburger manu */}
        <div
          className="contain-to-grid"
          style={{ marginTop: "2.5px", marginBottom: "2.5px" }}
        >
          <div
            className="small-3 columns"
            style={{ float: "right", marginRight: "40%", width: "auto" }}
          >
            <div
              className="off-canvas-content"
              data-off-canvas-content
              style={{ float: "right" }}
            >
              <div className="nav-bar">
                <div className="nav-bar-right" style={{ float: "right" }}>
                  <ul className="menu">
                    <li>
                      <button
                        className="offcanvas-trigger"
                        type="button"
                        data-open="offCanvasRight"
                        aria-expanded={isSliderOpen ? "true" : "false"}
                        aria-controls="offCanvasRight"
                        style={navigationButtonStyle}
                        onClick={isSliderOpen ? closeSlider : toggleSlider} // Attach toggleSlider function to onClick event
                      >
                        <div className="hamburger" style={{ color: "#d4be6a" }}>
                          <span className="line"></span>
                          <span className="line"></span>
                          <span className="line"></span>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Meny-slidern */}
        <div
          className={`off-canvas position-right ${
            isSliderOpen ? "is-open" : ""
          }`}
          id="offCanvasRight"
          data-off-canvas
          style={{ zIndex: 1000000 }}
        >
          <ul
            className="vertical menu"
            data-accordion-menu
            style={{ marginTop: "30px" }}
          >
            <li className="active">
              <a href="/">{translate("Home")}</a>
            </li>
            <li className=" ">
              <a href="/gallery">{translate("Gallery")}</a>
            </li>
            <li className=" ">
              <a href="/order">{translate("Order info")}</a>
            </li>
            <li className=" ">
              <a href="/about">{translate("About")}</a>
            </li>
            <li className=" ">
              <a href="/contact">{translate("Contact")}</a>
            </li>
            <li style={{ marginTop: "30px", textAlign: "left" }}>
              <p>
                <a href="https://www.instagram.com/tzettmarker/">
                  <img
                    className="nav-logo-small-menu"
                    src={instagramIcon}
                    alt="Instagram"
                  />
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

// Header
const Header = () => {
  // Hämta ScreenSizeContext from ScreenSizeProvider.js
  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  const headerImageStyle = {
    textAlign: "center",
    height: "auto",
    marginTop: isReady
      ? isMobileScreen
        ? isPortrait
          ? "4.4em"
          : "3.5em"
        : isTabletScreen
        ? isPortrait
          ? "3.5em"
          : "3.5em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "3.5em"
          : "5.5em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "3.5em"
          : "5.5em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "3.5em"
          : "5.5em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "5.5em"
          : "5.5em"
        : "5.5em"
      : "5.5em",
  };

  return (
    <div className="main-content-row">
      <div className="header-image" style={headerImageStyle}>
        <img
          src={headerImage}
          alt="HeaderImage"
          style={{ boxShadow: "rgb(0, 0, 0) 3px 1px 5px" }}
        />
      </div>
    </div>
  );
};

const Integrity = () => {
  // Hämta ScreenSizeContext from ScreenSizeProvider.js
  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);
  // Shoppingcart-style
  const shoppingCartStyle = {
    position: "fixed",
    zIndex: 10000,
    top: isReady
      ? isMobileScreen
        ? isPortrait
          ? "4.8em"
          : "5.8em"
        : isTabletScreen
        ? isPortrait
          ? "6em"
          : "7em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "6em"
          : "7em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "7em"
          : "7em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "8em"
          : "8em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "8em"
          : "8em"
        : "7em"
      : "7em",
    right: isReady
      ? isMobileScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isTabletScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isSmallDesktopScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isMediumDesktopScreen
        ? isPortrait
          ? "1em"
          : "1em"
        : isLargeDesktopScreen
        ? isPortrait
          ? "2em"
          : "2em"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "2em"
          : "2em"
        : "1em"
      : "1em",
  };
  // State to keep track of the selected language
  const { translate } = useLanguage();

  return (
    <div>
      {/* Bakgrundsbilden */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${GardientBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 1,
          zIndex: -1,
        }}
      />
      <div
        className="row"
        style={{
          marginTop: isMobileScreen
            ? isPortrait
              ? "18%"
              : "8%"
            : isTabletScreen
            ? isPortrait
              ? "18%"
              : "8%"
            : isSmallDesktopScreen
            ? isPortrait
              ? "10%"
              : "5%"
            : isMediumDesktopScreen
            ? isPortrait
              ? "10%"
              : "7%"
            : isLargeDesktopScreen
            ? "10%"
            : isExtraLargeDesktopScreen
            ? "5%"
            : "10%",
        }}
      >
        <h1 style={{ textAlign: "center" }}>
          <strong>{translate("Privacy Policy")}</strong>
        </h1>
        <p style={{ textAlign: "center" }}>
          <em>{translate("Updated", true)}</em>
        </p>

        <br />
        <br />

        <p>
          {translate("PrivacyPolicyText1", true)}
          <br />
          <br />
          <br />
          <h4>
            <strong>{translate("PrivacyPolicyText3", true)}</strong>
          </h4>
          <br />
          {translate("PrivacyPolicyText4", true)}
          <br />
          {translate("PrivacyPolicyText5", true)}
          <br />
          <br />
          {translate("PrivacyPolicyText6", true)}
          <br />
          {translate("PrivacyPolicyText7", true)}
          <br />
          {translate("PrivacyPolicyText8", true)}
          <br />
          <br />
          <br />
          <h4>
            <strong>{translate("PrivacyPolicyText9", true)}</strong>
          </h4>
          <br />
          {translate("PrivacyPolicyText10", true)}
          <br />
          <br />
          {translate("PrivacyPolicyText11", true)}
          <br />
          {translate("PrivacyPolicyText12", true)}
          <br />
          <br />
          <br />
          <h4>
            <strong>{translate("PrivacyPolicyText13", true)}</strong>
          </h4>
          <br />
          {translate("PrivacyPolicyText14", true)}
          <br />
          <br />
          {translate("PrivacyPolicyText15", true)}
          <br />
          <br />
          <br />
          <h4>
            <strong>{translate("PrivacyPolicyText16", true)}</strong>
          </h4>
          <br />
          {translate("PrivacyPolicyText17", true)}
          <br />
          <br />
          {translate("PrivacyPolicyText18", true)}
          <br />
          <br />
          {translate("PrivacyPolicyText19", true)}
          <br />
          <br />
          <br />
          <h4>
            <strong>{translate("PrivacyPolicyText20", true)}</strong>
          </h4>
          <br />
          {translate("PrivacyPolicyText21", true)}
          <br />
          {translate("PrivacyPolicyText22", true)}
          <br />
          <br />
          <br />
          <h4>
            <strong>{translate("PrivacyPolicyText23", true)}</strong>
          </h4>
          <br />
          {translate("PrivacyPolicyText24", true)}
          <br />
          <br />
          <br />
          <h4>
            <strong>{translate("PrivacyPolicyText28", true)}</strong>
          </h4>
          <br />
          {translate("PrivacyPolicyText29", true)}
          <br />
          <br />
          {translate("PrivacyPolicyText30", true)}
          <br />
          <br />
          <br />
          <h4>
            <strong>{translate("PrivacyPolicyText31", true)}</strong>
          </h4>
          <br />
          {translate("PrivacyPolicyText32", true)}
          <br />
          <br />

          {translate("PrivacyPolicyText33", true)}
          <a href="mailto:support@tzettmarker.se">
            {translate("PrivacyPolicyText34", true)}
          </a>
          <br />
          {translate("PrivacyPolicyText35", true)}
          <a href="https://tzettmarker.se/contact">
            {translate("PrivacyPolicyText36", true)}
          </a>
        </p>
      </div>
    </div>
  );
};

// Footer
const Footer = () => {
  // Hämta ScreenSizeContext from ScreenSizeProvider.js
  const {
    isReady,
    isPortrait,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    isMediumDesktopScreen,
    isLargeDesktopScreen,
    isExtraLargeDesktopScreen,
  } = useContext(ScreenSizeContext);

  // State to keep track of the selected language
  const { selectedLanguage, handleLanguageChange, translate } = useLanguage();

  const footerContainerStyle = {
    bottom: "0px",
    borderTop: "4px solid #878787",
    marginTop: isMobileScreen
      ? isPortrait
        ? "15%"
        : "10%"
      : isTabletScreen
      ? "10%"
      : isSmallDesktopScreen
      ? "16%"
      : isMediumDesktopScreen
      ? "16%"
      : isLargeDesktopScreen
      ? "12%"
      : isExtraLargeDesktopScreen
      ? "10%"
      : "10%",
    paddingTop: "3rem",
    backgroundColor: "#282828",
  };
  const sectionsContainerStyle = {
    maxWidth: isReady
      ? isMobileScreen
        ? isPortrait
          ? "95%"
          : "90%"
        : isTabletScreen
        ? isPortrait
          ? "85%"
          : "85%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "85%"
          : "85%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "75%"
          : "80%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "75%"
          : "75%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "70%"
          : "70%"
        : "75%"
      : "75%",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const rightSectionStyle = {
    textAlign: isMobileScreen ? "left" : "right", // Ändra textjustering för mobilskärmar
    float: "left",
    width: isReady
      ? isMobileScreen
        ? isPortrait
          ? "100%"
          : "100%"
        : isTabletScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : "33.3%"
      : "33.3%",
  };
  const centerSectionStyle = {
    textAlign: isMobileScreen ? "left" : "center", // Ändra textjustering för mobilskärmar
    float: "left",
    width: isReady
      ? isMobileScreen
        ? isPortrait
          ? "100%"
          : "100%"
        : isTabletScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : "33.3%"
      : "33.3%",
  };
  const leftSectionStyle = {
    textAlign: isMobileScreen ? "left" : "left", // Ändra textjustering för mobilskärmar
    float: "left",
    width: isReady
      ? isMobileScreen
        ? isPortrait
          ? "100%"
          : "100%"
        : isTabletScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isSmallDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isMediumDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : isExtraLargeDesktopScreen
        ? isPortrait
          ? "33.3%"
          : "33.3%"
        : "33.3%"
      : "33.3%",
  };
  const copyrightStyle = {
    textAlign: isMobileScreen ? "left" : "center", // Ändra textjustering för mobilskärmar
    marginLeft: isMobileScreen ? "1em" : "0em", // Ändra textjustering för mobilskärmar
    bottom: "1rem", // Använd önskat avstånd från botten
    color: "#444",
    padding: "1rem",
  };

  return (
    // Footer div
    <div style={footerContainerStyle}>
      {/* Sektioner-container */}
      <div style={sectionsContainerStyle}>
        {/* Sektionerna - innehållet */}
        <div style={leftSectionStyle}>
          <h3>{translate("Featured Sections")}</h3>
          <p>
            <a href="/">{translate("Home")}</a>
            <br />
            <a href="/gallery">{translate("Gallery")}</a>
            <br />
            <a href="/order">{translate("Order info")}</a>
            <br />
            <a href="/about">{translate("About")}</a>
            <br />
            <a href="/contact">{translate("Contact")}</a>
            <br />
          </p>
        </div>
        <div style={centerSectionStyle}>
          <h3>{translate("ContactTZETTMARKER")}</h3>
          <p>
            <a href="mailto:support@tzettmarker.se">support@tzettmarker.se</a>
          </p>
          <p>
            <a href="https://www.instagram.com/tzettmarker/">
              <img
                className="nav-logo-small"
                src={instagramIcon}
                alt="InstagramLogo"
              />
            </a>
          </p>
        </div>
        <div style={rightSectionStyle}>
          <h3>{translate("Our Website")}</h3>
          <p>
            <a href="/integrity">{translate("Privacy Policy")}</a>
            <br />
            <img
              className="nav-logo-small"
              src={swishLogo}
              alt="swishLogo"
              style={{ maxWidth: "2em", marginTop: "3em" }}
            />
          </p>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {/* Copyright */}
      <div style={copyrightStyle}>
        {translate("Website designed by")} {translate("Rebecka Zettmarker")}
        {" © "}
        {new Date().getFullYear()}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <ScreenSizeProvider>
      <div className="App">
        <Navigation />
        <Header />
        <Integrity />
        <Footer />
      </div>
    </ScreenSizeProvider>
  );
};

export default App;
