import React, { useState, createContext, useContext, useEffect } from "react";
import svTranslations from "./sv.json";
import enTranslations from "./en.json";
import Cookies from "js-cookie";

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("sv");

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  const translate = (key) => {
    const translations = {
      en: enTranslations,
      sv: svTranslations,
    };
    return translations[selectedLanguage][key] || key;
  };

  useEffect(() => {
    // Load selected language from cookies during initial rendering
    const savedLanguage = Cookies.get("selectedLanguage");
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  useEffect(() => {
    // Update cookies when the selected language changes
    Cookies.set("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <LanguageContext.Provider
      value={{ selectedLanguage, handleLanguageChange, translate }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => useContext(LanguageContext);

export { LanguageProvider, useLanguage };
